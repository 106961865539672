import React from "react";
import ProgressHeader from "./ProgressHeader";
import views from "./screenerViewsNames";

const steps = [
  { viewNames: undefined, displayName: "Your family" },
  { viewNames: [views.insuranceEligibility], displayName: "Check coverage" },
  {
    viewNames: [views.passwordInput, views.loadingAccountCreation, views.providerSelection],
    displayName: "Book session"
  },
  { viewNames: [views.scheduleConsult, views.intakeConfirmation, views.addInsuranceCard], displayName: "Get started" }
];

export default function CareScreenerProgress({ currentView }) {
  const stepNames = steps.map(s => s.displayName);
  const currentStep = steps.find(s => s.viewNames && s.viewNames.includes(currentView));
  const currentStepIndex = currentStep ? steps.indexOf(currentStep) : -1;

  return <ProgressHeader steps={stepNames} index={currentStepIndex} />;
}
