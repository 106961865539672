import { Route, Routes } from "react-router-dom";
import React from "react";
import ConsultPage from "../pages/Consult";
import PCScreener from "../pages/PCScreener";
import NewAccount from "../pages/NewAccount";
import NewAccountAndConsult from "../pages/NewAccountAndConsult";
import Lyra from "../pages/Lyra";
import Forms from "../pages/Forms";

export default function ScreenerRoutes() {
  return (
    <Routes>
      <Route path="/consult" element={<ConsultPage />} />
      <Route path="/pcscreener" element={<PCScreener />} />
      <Route path="/lyra" element={<Lyra />} />
      <Route path="/newaccount" element={<NewAccount />} />
      <Route path="/newaccountandconsult" element={<NewAccountAndConsult />} />
      <Route path="/forms" element={<Forms />} />
    </Routes>
  );
}
