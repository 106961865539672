import React from "react";
import Flex from "../core/Flex";

type Props = {
  children?: React.ReactNode;
};

export default function CareScreenerBackground({ children }: Props) {
  return (
    <Flex center className="background-image-container">
      {children}
    </Flex>
  );
}
