import React, { ReactNode, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { ChevronLeft } from "react-feather";
import { StyleSheet, css } from "aphrodite";
import Text from "../core/Text";
import Flex from "../core/Flex";
import Span from "../core/Span";
import useCareScreenerWidth from "../../hooks/care-screener/useCareScreenerWidth";
import { MANA_BLUE, MANA_BLUE_DARK, MANA_BLUE_EXTRA_LIGHT } from "../../theme/colors";
import { AccountType, User } from "../../model/userModels";
import moment from "moment";
import { Icon } from "@shopify/polaris";
import { InfoIcon, StarIcon } from "@shopify/polaris-icons";
import Button from "../Button";
import ProviderCard from "./ProviderCard";
import { CareScreenerContext } from "../../context/CareScreenerContext";
import useCareTeamMemberMatches from "../../hooks/useCareTeamMemberMatches";
import Spinner from "../Spinner";
import mooShooSearch from "../../assets/care-screener/mooshoo-search.png";
import MatchedCareProviderSelector, { MatchedCareProvider } from "../appointments/MatchedCareProviderSelector";
import AppointmentScheduler from "../appointments/AppointmentScheduler";
import BookedIntake from "../appointments/BookedIntake";
import config from "../../config/env";
import { Appointment } from "../../model/appointmentModels";

export type ProviderSelectionProps = {
  careCoordinator: User | undefined;
  progressComponent?: ReactNode;
  state: string;
  onBookConsultPressed: () => void;
  onContinueButtonPressed: () => void;
  continueButtonLabel: string;
  schedulingQueryStartDate?: string | null;
};

export default function ProviderSelection({
  careCoordinator,
  progressComponent,
  state,
  onBookConsultPressed,
  onContinueButtonPressed,
  continueButtonLabel,
  schedulingQueryStartDate
}: ProviderSelectionProps) {
  const { candidates, candidateIterator } = useContext(CareScreenerContext)!;
  const { widthStyle } = useCareScreenerWidth();
  const [clientFirstName, setClientFirstName] = useState<string | undefined>(candidateIterator.current?.user.firstName);

  const [selectedCareProvider, setSelectedCareProvider] = useState<MatchedCareProvider | undefined>(undefined);
  const [scheduledAppointment, setScheduledAppointment] = useState<Appointment | undefined>(undefined);
  const [view, setView] = useState<"matches" | "scheduler" | "scheduled" | "noResults">("matches");
  const [appointmentUserId] = useState(candidateIterator.current?.user.id);
  const [appointmentTypeId] = useState(config.env.REACT_APP_INTAKE_APPT_TYPE_ID);

  const { data, loading, careTeamMembers } = useCareTeamMemberMatches(
    candidateIterator.current ? [candidateIterator.current?.user.id] : []
  );

  useEffect(() => {
    setClientFirstName(candidateIterator.current?.user.firstName);
  }, [candidateIterator]);

  const theBasicsCards = useMemo(() => {
    const basicCards = [
      <SpecializationCard key={1} innerText={"Child and Family Therapist"} />,
      <SpecializationCard key={2} innerText={`In ${state}`} />
    ];
    const insuranceCardSet: Set<string> = new Set();
    const ageWindowSet: Set<string | undefined> = new Set([undefined]);
    const insuranceCards: ReactNode[] = [];
    const ageCards = candidates
      ? candidates.map(candidate => {
          const age = moment().diff(moment(candidate.user.dateOfBirth), "years");
          const ageWindow = findAgeWindow(age);
          if (
            !insuranceCardSet.has(candidate.insurancePlanName) &&
            candidate.insuranceEligibilityStatus === "ELIGIBLE"
          ) {
            insuranceCards.push(
              <SpecializationCard key={candidate.insurancePlanName} innerText={`${candidate.insurancePlanName}`} />
            );
            insuranceCardSet.add(candidate.insurancePlanName);
          }

          if (!ageWindowSet.has(ageWindow)) {
            ageWindowSet.add(ageWindow);
            return <SpecializationCard key={candidate.user.id} innerText={`Age ${ageWindow}`} />;
          }
          return undefined;
        })
      : [];

    return [...basicCards, ...ageCards, ...insuranceCards];
  }, [state, candidates]);

  const careProviders = useMemo(
    () => careTeamMembers.filter(ctm => AccountType.isCareProvider(ctm.accountType)).slice(0, 3),
    [careTeamMembers]
  );

  const onScheduleNextClientButtonPress = useCallback(() => {
    candidateIterator.next();
    setView("matches");
  }, [candidateIterator]);

  useEffect(() => {
    if (!loading && data && !careProviders.length) {
      setView("noResults");
    }
  }, [loading, data, careProviders.length]);

  return (
    <Flex className={css(styles.container)} style={widthStyle}>
      {progressComponent}
      {loading && (
        <>
          <Flex center>
            <Text title center size={24} style={{ paddingTop: 20 }}>
              Looking for your match...
            </Text>
          </Flex>
          <Flex center style={{ marginTop: 20 }}>
            <Spinner inline />
          </Flex>
        </>
      )}
      {view === "noResults" && (
        <>
          <Flex center>
            <Text title center size={24} style={{ paddingTop: 20 }}>
              Still looking for your match... 🔍
            </Text>
            <img src={mooShooSearch} style={{ width: "50%", maxWidth: 300, marginTop: 20, objectFit: "contain" }} />
            <Text center style={{ paddingTop: 20 }}>
              We couldn't match you with the perfect therapist, but we're working on it!
              <br />
              <br />
              We'll reach out in the next 24 hours. In the meantime, feel free to book a consult.
            </Text>
          </Flex>
          <ProviderCard provider={careCoordinator} innerText="">
            {" "}
            <Text>
              I'm here to help!{" "}
              <a href="sms:2135584348" style={{ textDecoration: "none" }}>
                <Span weight={800} theme="manaBlue" style={{ textDecoration: "underline" }}>
                  Text me
                </Span>
              </a>{" "}
              at (213) 558-4348 or book a consult below.
            </Text>
          </ProviderCard>
          <Button theme="manaBlue" title="book a consult" onPress={onBookConsultPressed} style={{ marginTop: 20 }} />
        </>
      )}
      {view === "matches" && (
        <>
          <Flex center>
            <Text title center size={24} style={{ paddingTop: 20 }}>
              Let's select a therapist ☀️
            </Text>
          </Flex>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              paddingTop: 20,
              gap: 10
            }}
          >
            <Text color={MANA_BLUE} style={{ fontSize: 40 }}>
              <Icon source={InfoIcon} />
            </Text>
            <Text weight={800} color={MANA_BLUE} style={{ flexGrow: 1 }}>
              We looked at:
            </Text>
          </div>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              paddingTop: 20,
              gap: 5
            }}
          >
            {theBasicsCards}
          </div>
          <HorizontalLine />

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              paddingTop: 20,
              paddingBottom: 20,
              gap: 10
            }}
          >
            <Text color={MANA_BLUE} style={{ fontSize: 40 }}>
              <Icon source={StarIcon} />
            </Text>
            <Text weight={800} color={MANA_BLUE} style={{ flexGrow: 1 }}>
              Select {clientFirstName}'s therapist:
            </Text>
          </div>
          <Flex style={{ gap: 12 }}>
            {careProviders.map(careProvider => (
              <MatchedCareProviderSelector
                key={careProvider.id}
                careProvider={careProvider}
                onCareProviderSelected={() => {
                  setSelectedCareProvider(careProvider);
                  setView("scheduler");
                }}
                selectButtonTitle="schedule now"
              />
            ))}
          </Flex>
          <HorizontalLine />

          <Text weight={800} color={MANA_BLUE_DARK} style={{ paddingTop: 20 }}>
            Not seeing a match that works for your family? Let us know.
          </Text>
          <br />
          <Text>
            <a href="tel:2135584348" style={{ textDecoration: "none" }}>
              <Span weight={800} theme="manaBlue" style={{ textDecoration: "underline" }}>
                Call
              </Span>
            </a>
            {" or "}
            <a href="sms:2135584348" style={{ textDecoration: "none" }}>
              <Span weight={800} theme="manaBlue" style={{ textDecoration: "underline" }}>
                Text me
              </Span>{" "}
            </a>
            at (213) 558-4348
          </Text>
        </>
      )}
      {view === "scheduler" && (
        <AppointmentScheduler
          appointmentId={appointmentTypeId}
          appointmentUserId={appointmentUserId}
          availabilityRecurringCount={3}
          schedulingQueryStartDate={schedulingQueryStartDate}
          availabilityRecurringInterval="Weekly"
          appointmentContactMethod="Healthie Video Call"
          onAppointmentScheduled={appointment => {
            setScheduledAppointment(appointment);
            setView("scheduled");
          }}
          careTeamMemberIds={[selectedCareProvider?.id]}
          hideCalendarIcon
          headerComponent={
            <Flex
              flex
              row
              center
              left
              style={{
                paddingBottom: 10,
                borderBottomStyle: "solid",
                borderBottomWidth: 1,
                borderBottomColor: "#E0E0E0"
              }}
            >
              <Button
                theme="manaBlueInverted"
                icon={ChevronLeft}
                iconSize={40}
                iconLeft
                title={() => (
                  <Text theme="manaBlue" weight={700} size={14}>
                    Back to matches
                  </Text>
                )}
                style={{ padding: 0, marginLeft: 10 }}
                onPress={() => setView("matches")}
              />
            </Flex>
          }
        />
      )}
      {view === "scheduled" && scheduledAppointment && (
        <BookedIntake
          appointment={scheduledAppointment}
          onContinueButtonPressed={onContinueButtonPressed}
          continueButtonLabel={continueButtonLabel}
          onScheduleNextClientButtonPress={onScheduleNextClientButtonPress}
          matchedCareProvider={selectedCareProvider}
        />
      )}
    </Flex>
  );
}

function SpecializationCard({ innerText }) {
  return <Text style={styles.specializationCard}>✦ {innerText}</Text>;
}

function findAgeWindow(value: number) {
  const ageWindows = [
    [4, 8],
    [8, 12],
    [12, 18]
  ];
  for (let i = 0; i < ageWindows.length; i++) {
    const [min, max] = ageWindows[i];
    if (value >= min && value <= max) {
      if (i < ageWindows.length - 1 && value === ageWindows[i + 1][0]) {
        continue;
      }
      return ageWindows[i].join(" - ");
    }
  }
  return undefined;
}

export function HorizontalLine() {
  return (
    <Flex
      fullWidth
      center
      style={{
        borderTop: "solid",
        borderTopWidth: 2,
        borderTopColor: "#E0E0E0",
        marginTop: 20
      }}
    />
  );
}

const styles = StyleSheet.create({
  specializationCard: {
    backgroundColor: MANA_BLUE_EXTRA_LIGHT,
    color: MANA_BLUE_DARK,
    fontWeight: "normal",
    width: "max-content",
    borderRadius: 8,
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 4,
    paddingBottom: 4
  },
  container: {
    marginTop: 50,
    marginBottom: 50,
    padding: "5%",
    paddingTop: 10,
    backgroundColor: "#FFFFFF",
    marginLeft: "auto",
    marginRight: "auto",
    borderRadius: 15
  }
});
