import React from "react";
import { TouchableOpacity } from "./react-native";
import { XIcon } from "@shopify/polaris-icons";
import { Icon } from "@shopify/polaris";

export default function ExitButton({ onPress }) {
  return (
    <TouchableOpacity style={{ position: "absolute", top: 10, right: 10, width: 50, height: 50 }} onPress={onPress}>
      <Icon source={XIcon} />
    </TouchableOpacity>
  );
}
