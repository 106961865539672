import React from "react";
import { TextField, TextFieldProps } from "@shopify/polaris";

export type StyleSheet = { [key: string]: React.CSSProperties };
export type Style = React.CSSProperties;

export type TouchableOpacityProps = {
  onPress: () => void;
  style?: Style | Style[];
  children?: React.ReactNode;
};

export type ViewProps = {
  style?: Style | Style[];
  children?: React.ReactNode;
};

export type TextProps = {
  style?: Style | Style[];
  children?: React.ReactNode;
};

export type ImageProps = {
  style?: Style | Style[];
  source: string;
};

export type TextInputProps = TextFieldProps;

export function TouchableOpacity({ onPress, style, children }: TouchableOpacityProps) {
  return (
    <div
      className="TouchableOpacity"
      onClick={onPress}
      style={{
        display: "flex",
        flexDirection: "column",
        cursor: "pointer",
        position: "relative",
        ...convert(style)
      }}
    >
      {children}
    </div>
  );
}

export function View({ style, children }: ViewProps) {
  return (
    <div className="View" style={{ display: "flex", flexDirection: "column", ...convert(style) }}>
      {children}
    </div>
  );
}

export function Text({ style, children }: TextProps) {
  return (
    <span className="Text" style={{ display: "flex", flexDirection: "column", ...convert(style) }}>
      {children}
    </span>
  );
}

export function Image({ style, source }: ImageProps) {
  return (
    <div
      className="Image"
      style={{
        display: "flex",
        flexDirection: "column",
        backgroundSize: "cover",
        backgroundImage: `url(${source})`,
        ...convert(style)
      }}
    />
  );
}

export function TextInput(props: TextInputProps) {
  return <TextField {...props} />;
}

function convert(style: React.CSSProperties | React.CSSProperties[] | undefined): React.CSSProperties | undefined {
  if (Array.isArray(style)) {
    return style.reduce((memo, s) => {
      if (s) {
        return Object.assign({}, memo, s);
      }
      return memo;
    }, {});
  } else {
    return style;
  }
}

type StyleMapping<T> = { [K in keyof T]: React.CSSProperties };

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function createStyleSheet<T extends StyleMapping<T>>(styles: T & StyleMapping<any>): T {
  return styles;
}
