import React, { ReactNode, useCallback, useContext, useEffect, useMemo, useState } from "react";
import CareScreenerService, { InsuranceEstimate } from "../../services/CareScreenerService";
import ConsultLoading from "./CareScreenerLoading";
import { reportError } from "../../utils/errorUtils";
import { StyleSheet, css } from "aphrodite";
import { screenerStyles } from "./careScreenerStyles";
import InsuranceEligibleCoverage from "./insurance/InsuranceEligibleCoverage";
import InsuranceUnknownCoverage from "./insurance/InsuranceUnknownCoverage";
import Button from "../Button";
import { CareScreenerContext } from "@context/CareScreenerContext";

type Props = {
  careScreenerSubmission: {
    id: number;
    typeformId: string;
    typeformResponseId: string;
  };
  onContinue: () => void;
  progressComponent?: ReactNode;
};

const loadingTextVariants = [
  { text: "Saving your responses...", subText: "This should only take a few seconds." },
  { text: "Checking your payment options...", subText: "If you provided insurance, we're checking your coverage too." }
];

export default function InsuranceEligibility({ careScreenerSubmission, onContinue, progressComponent }: Props) {
  const { setInsuranceEligibilityStatus: setEligibilityStatus, insuranceEligibilityStatus: eligibilityStatus } =
    useContext(CareScreenerContext)!;

  const [view, setView] = useState("loading");
  const [insuranceEstimate, setInsuranceEstimate] = useState<InsuranceEstimate>();

  const checkInsuranceEligibility = useCallback(async () => {
    try {
      await CareScreenerService.checkCareScreenerInsuranceEligibility({
        careScreenerSubmissionId: careScreenerSubmission.id,
        typeformId: careScreenerSubmission.typeformId,
        typeformResponseId: careScreenerSubmission.typeformResponseId
      }).then(r => {
        setInsuranceEstimate(r[0]?.insuranceEstimate);
        setEligibilityStatus(r[0]?.eligibilityStatus || "UNKNOWN");
      });
    } catch (error) {
      setEligibilityStatus("UNKNOWN");
      reportError(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    checkInsuranceEligibility();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onLoadingTextRotation = useCallback(() => {
    if (eligibilityStatus) {
      if (eligibilityStatus === "NOT_APPLICABLE") {
        onContinue();
      } else {
        setView("eligibility");
      }
    }
  }, [eligibilityStatus, onContinue]);

  const continueButton = useMemo(
    () => (
      <Button
        style={{ width: 230, backgroundColor: "#2562D8", margin: 10 }}
        onPress={onContinue}
        title="keep going"
        theme="manaBlue"
      />
    ),
    []
  );

  return (
    <>
      {view === "loading" && (
        <div
          className={css(
            screenerStyles.whiteContainerRoundedEdges,
            screenerStyles.columnCentered,
            screenerStyles.padding
          )}
        >
          {progressComponent}
          <ConsultLoading textVariants={loadingTextVariants} onTextRotation={onLoadingTextRotation} />
          <p className={css(styles.footerText)}>
            "My child is super proud of himself and I am still shocked at how easy a transition it was for him."
            <br />
            -Manatee parent
          </p>
        </div>
      )}
      {view === "eligibility" && (
        <>
          {eligibilityStatus === "ELIGIBLE" ? (
            <InsuranceEligibleCoverage
              insuranceEstimate={insuranceEstimate}
              progressComponent={progressComponent}
              continueButton={continueButton}
            />
          ) : (
            <InsuranceUnknownCoverage progressComponent={progressComponent} continueButton={continueButton} />
          )}
        </>
      )}
    </>
  );
}

const styles = StyleSheet.create({
  footerText: {
    textAlign: "center",
    color: "#1A2579"
  }
});
