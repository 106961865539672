import React, { useContext, useState } from "react";
import Text from "../core/Text";
import { createStyleSheet } from "../react-native";
import Button from "../Button";
import InsuranceCardImage from "@assets/add-insurance-card.png";
import Flex from "../core/Flex";
import PhotoUploader from "@components/system/PhotoUploader";
import { FileData } from "@utils/fileUtils";
import { useMutation } from "@apollo/client";
import { CREATE_INSURANCE_CARD } from "../../queries";
import useFamilyMembers from "../../hooks/useFamilyMembers";
import { reportError } from "../../utils/errorUtils";
import { UserContext } from "../../context/UserContext";
import { useGlobalContext } from "../../context/GlobalComponentsContext";
import { logEvent } from "@actions";
import CareScreenerCard from "@components/care-screener/CareScreenerCard";

type Props = {
  onContinuePress: (cardAdded: boolean) => void;
  required?: boolean;
  logEventParams?: object;
  headerComponent?: React.ReactNode;
};

export default function AddInsuranceCard({ onContinuePress, required, logEventParams, headerComponent }: Props) {
  const { user } = useContext(UserContext) || {};
  const [cardFrontImage, setCardFrontImage] = useState<FileData | null>();
  const [cardBackImage, setCardBackImage] = useState<FileData | null>();
  const { showError } = useGlobalContext();
  const [loading, setLoading] = useState(false);

  const [createInsuranceCardMutation] = useMutation(CREATE_INSURANCE_CARD);

  const { familyMembers, loading: loadingFamilyMembers } = useFamilyMembers();

  return (
    <Flex center>
      <CareScreenerCard>
        <Flex center style={{ margin: 15 }}>
          {headerComponent}
          <Text style={{ marginTop: 10 }} size={24} weight={700} theme="manaBlueDark">
            Your insurance card
          </Text>
          <img src={InsuranceCardImage} style={styles.image} />
          <Text center color="#212328" weight={400} size={16} style={{ margin: 20, width: 300 }}>
            To use your insurance for your upcoming session, please provide a clear photo of both the front and back of
            your card.
          </Text>
          <PhotoUploader
            cameraContainerStyle={styles.cameraContainer}
            label="Front of insurance card"
            newFileName="Insurance_Card_Front"
            onFileChange={setCardFrontImage}
          />
          <div
            style={{
              width: "100%",
              height: 1,
              margin: 10,
              backgroundColor: "#E0E0E0"
            }}
          />
          <PhotoUploader
            cameraContainerStyle={styles.cameraContainer}
            label="Back of insurance card"
            newFileName="Insurance_Card_Back"
            onFileChange={setCardBackImage}
          />
          <Flex row fullWidth center style={styles.buttonContainer}>
            <Flex fullWidth>
              <Button
                loading={loading}
                disabled={!cardFrontImage || !cardBackImage || loadingFamilyMembers || loading}
                theme="manaBlue"
                style={styles.button}
                title="Submit"
                fullWidth
                onPress={async () => {
                  try {
                    setLoading(true);
                    logEvent("addInsuranceCardSubmitted", logEventParams);

                    await createInsuranceCardMutation({
                      variables: {
                        userIds: familyMembers.map(member => member.id),
                        frontImage: cardFrontImage,
                        backImage: cardBackImage
                      }
                    });
                    onContinuePress(true);
                  } catch (error) {
                    logEvent("addInsuranceCardError", logEventParams);
                    showError();
                    reportError(`addInsuranceCard - userId=${user?.id}`, error);
                  } finally {
                    setLoading(false);
                  }
                }}
              />
            </Flex>
          </Flex>
          {!required && !loading && (
            <Text
              style={{ marginTop: 10 }}
              link={{
                onPress: () => {
                  logEvent("addInsuranceCardLater", logEventParams);
                  onContinuePress(false);
                }
              }}
            >
              I'll do this later
            </Text>
          )}
        </Flex>
      </CareScreenerCard>
    </Flex>
  );
}

const styles = createStyleSheet({
  image: {
    width: 200,
    height: 200,
    margin: 10,
    objectFit: "cover"
  },
  button: {
    padding: 15,
    width: "100%"
  },
  buttonContainer: {
    marginTop: 20
  },
  cameraContainer: {
    maxWidth: 800,
    borderRadius: 20
  }
});
