import React, { useState, useEffect, useMemo } from "react";
import "./MatchProviders.css";
import useCareTeam from "../hooks/useCareTeam";
import Button from "./Button";
import { orderBy } from "lodash";
import { User } from "../model/userModels";
import { useMutation, useQuery } from "@apollo/client";
import { GET_CARE_TEAM_MATCHES, SET_CARE_TEAM_MEMBER_MATCHES, SEND_CARE_TEAM_MEMBER_MATCHES_EMAIL } from "../queries";
import { useGlobalContext } from "../context/GlobalComponentsContext";
import Spinner from "./Spinner";
import { useActions } from "tiny-atom/react/hooks";
import ButtonStack from "./ButtonStack";
import { reportError } from "../utils/errorUtils";
import { ChevronDown, ChevronUp, Copy } from "react-feather";
import usePatientFamilyMembers from "../hooks/usePatientFamilyMembers";
import { Collapsible } from "@shopify/polaris";
import { TouchableOpacity } from "./react-native";
import Flex from "./core/Flex";

const baseUrl =
  process.env.NODE_ENV === "development"
    ? "http://localhost:3000/schedule-intake?clientId="
    : "https://home.getmanatee.com/schedule-intake?clientId=";

function generateIntakeLink(clientId): string {
  return baseUrl + `${clientId}`;
}

function getFullNameWithEmail(parent): string {
  return `${parent.firstName} ${parent.lastName} (${parent.email})`;
}

function getFullName(user: User): string {
  return `${user.firstName} ${user.lastName}`;
}

const UNSAVED_CHANGES_STRING = `Unsaved changes exist`;
export default function MatchProviders({ clientId }) {
  const { showError } = useGlobalContext();
  const { parents } = usePatientFamilyMembers();
  const { displayToast } = useActions();
  const careTeam = useCareTeam();
  const [selectedProviders, setSelectedProviders] = useState<User[]>([]);
  const [selectedRecipients, setSelectedRecipients] = useState<User[]>(parents);
  const [sendEmailButtonDisabled, setSendEmailButtonDisabled] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [urlCollapsed, setUrlCollapsed] = useState(true);
  const [matchesSaved, setMatchesSaved] = useState(false);
  const [warningString, setWarningString] = useState(UNSAVED_CHANGES_STRING);

  const {
    data,
    loading: loadingMatches,
    error,
    refetch
  } = useQuery(GET_CARE_TEAM_MATCHES, {
    variables: { userIds: [clientId] },
    fetchPolicy: "no-cache"
  });

  const loading = loadingMatches || processing;

  useEffect(() => {
    if (error) {
      reportError(error);
      showError();
    }
  }, [error, showError]);

  const [setCareTeamMemberMatchesMutation] = useMutation(SET_CARE_TEAM_MEMBER_MATCHES);
  const [sendCareTeamMemberMatchesEmailMutation] = useMutation(SEND_CARE_TEAM_MEMBER_MATCHES_EMAIL);

  const therapists = useMemo(() => {
    return orderBy(careTeam.therapists || [], [item => item.firstName.toLowerCase()], ["asc"]);
  }, [careTeam]);

  const handleProviderSelect = provider => {
    setMatchesSaved(false);
    if (selectedProviders.some(p => p.id === provider.id)) {
      setSelectedProviders(selectedProviders.filter(p => p.id !== provider.id));
    } else if (selectedProviders.length < 3) {
      setSelectedProviders([...selectedProviders, provider]);
    }
  };

  const handleRecipientsSelect = parent => {
    if (selectedRecipients.some(p => p.id === parent.id)) {
      setSelectedRecipients(selectedRecipients.filter(p => p.id !== parent.id));
    } else {
      setSelectedRecipients([...selectedRecipients, parent]);
    }
  };

  useEffect(() => {
    let warning = ``;
    if (matchesSaved && selectedRecipients.length > 0 && selectedProviders.length > 0) {
      setSendEmailButtonDisabled(false);
    } else {
      setSendEmailButtonDisabled(true);
    }
    if (selectedRecipients.length === 0) {
      warning += "No recipients selected. ";
    }
    if (selectedProviders.length === 0) {
      warning += "No providers selected. ";
    }
    if (!matchesSaved) {
      warning += UNSAVED_CHANGES_STRING;
    }
    if (warning.length > 0) {
      setWarningString(warning);
    }
  }, [matchesSaved, selectedRecipients, selectedProviders]);

  useEffect(() => {
    if (data) {
      const queriedMatchedProviders = therapists.filter(provider =>
        data.careTeamMemberMatches.some(match => match.careTeamMember.id === provider.id)
      );
      setSelectedProviders(queriedMatchedProviders);
      if (queriedMatchedProviders.length > 0) {
        setMatchesSaved(true);
      }
    }
  }, [data, therapists]);

  async function onSendEmailButtonPress() {
    setSendEmailButtonDisabled(true);
    setWarningString(`Email sent`);
    try {
      setProcessing(true);
      const { data } = await sendCareTeamMemberMatchesEmailMutation({
        variables: {
          contextUserId: clientId,
          emailRecipientUserIds: selectedRecipients.map(user => user.id)
        }
      });

      const emailRes: boolean[] = data.sendCareTeamMemberMatchesEmail?.mutatedObjects?.map(
        e => JSON.parse(e.data).success
      );

      const emailFailIds: string[] = [];
      let emailSuccess = 0;
      let emailFails = 0;

      emailRes.forEach((res, index) => {
        res ? emailSuccess++ : emailFails++;
        if (!res) {
          emailFailIds.push(selectedRecipients[index]?.firstName);
        }
      });
      let text = `Email`;
      text += emailSuccess ? ` sent to ${emailSuccess} caregivers!` : ``;
      text += emailFails ? ` failed to send to ${emailFails} caregivers 😢 [${emailFailIds}]` : ``;

      displayToast({ text });
    } catch (error) {
      setSendEmailButtonDisabled(false);
      setWarningString(`Email failed to send`);
      showError();
    } finally {
      setProcessing(false);
    }
  }

  async function onSaveProvidersButtonPress() {
    try {
      setProcessing(true);
      await setCareTeamMemberMatchesMutation({
        variables: {
          userId: clientId,
          careTeamMemberIds: selectedProviders.map(p => p.id),
          retainCareCoordinatorMatches: true
        }
      }).then(() => refetch());
      displayToast({ text: "Matched providers saved" });
      setMatchesSaved(true);
    } catch (error) {
      showError();
      setMatchesSaved(false);
    } finally {
      setProcessing(false);
    }
  }
  return (
    <div className="provider-matching-container">
      <p>Select up to 3 providers from the list below to generate an intake scheduling link.</p>
      <ul className="provider-list">
        {loading ? (
          <Flex center>
            <Spinner inline />
          </Flex>
        ) : (
          <>
            {therapists.map(provider => (
              <li key={provider.id}>
                <input
                  type="checkbox"
                  id={provider.id.toString()}
                  checked={selectedProviders.some(p => p.id === provider.id)}
                  onChange={() => handleProviderSelect(provider)}
                  disabled={!selectedProviders.some(p => p.id === provider.id) && selectedProviders.length >= 3}
                />
                <label htmlFor={provider.id.toString()}>{getFullName(provider)}</label>
              </li>
            ))}
          </>
        )}
      </ul>
      <hr />
      <div className="selected-providers">
        <strong>Selected providers:</strong>
        <br />
        {selectedProviders.map(p => getFullName(p)).join(", ") || "None"}
      </div>
      <hr />
      <div className="selected-recipients">
        <span>Select the caregivers to receive the intake scheduling email.</span>
        {parents.map(parent => (
          <li key={parent.id}>
            <input
              type="checkbox"
              id={parent.id.toString()}
              checked={selectedRecipients.some(p => p.id === parent.id)}
              onChange={() => handleRecipientsSelect(parent)}
            />
            <label htmlFor={parent.id.toString()}>{getFullNameWithEmail(parent)}</label>
          </li>
        ))}
      </div>
      <hr />
      <div className="intake-link">
        <TouchableOpacity
          style={{
            flexDirection: "row",
            paddingBottom: "10px",
            justifyContent: "space-between"
          }}
          onPress={() => {
            setUrlCollapsed(!urlCollapsed);
          }}
        >
          <strong>Intake scheduling link</strong>
          {urlCollapsed ? <ChevronUp size={17} /> : <ChevronDown size={17} />}
        </TouchableOpacity>
        <Collapsible
          id="schedule-intake-link-collapsible"
          open={!urlCollapsed}
          transition={{ duration: "500ms", timingFunction: "ease-in-out" }}
        >
          <div id="schedule-intake-link-text">
            <h1>{generateIntakeLink(clientId)}</h1>
            <Button
              theme="iconButton"
              icon={Copy}
              feather
              small
              style={{ width: "50px", right: "0px" }}
              onPress={() => {
                navigator.clipboard.writeText(generateIntakeLink(clientId)).then(() => {
                  displayToast({ text: "Link Copied!" });
                });
              }}
            />
          </div>
        </Collapsible>
      </div>
      <div className="save-and-send-buttons">
        <Collapsible
          id="send-email-disabled-warning"
          open={sendEmailButtonDisabled}
          transition={{ duration: "500ms", timingFunction: "ease-in-out" }}
        >
          <h1 className="unsaved-changes">{warningString}</h1>
        </Collapsible>
        <ButtonStack horizontal fullWidth>
          <Button
            theme="manaBlueLight"
            title="save matches"
            onPress={onSaveProvidersButtonPress}
            disabled={loading}
            loading={loading}
          />
          <Button
            theme="manaBlue"
            title={"send email"}
            onPress={onSendEmailButtonPress}
            disabled={sendEmailButtonDisabled}
          />
        </ButtonStack>
      </div>
    </div>
  );
}
