import React, { useState } from "react";
import AddInsuranceCard from "@components/billing/AddInsuranceCard";
import { Flex, Text } from "@core";
import CareScreenerBackground from "@components/care-screener/CareScreenerBackground";
import CareScreenerNarrowCard from "@components/care-screener/CareScreenerNarrowCard";
import Button from "@components/Button";
import { useNavigate } from "react-router-dom";
import ButtonStack from "@components/ButtonStack";
import ThankYouImage from "@assets/care-screener/mooshoo-thank-you.png";

export default function AddInsuranceCardPage() {
  const [cardAdded, setCardAdded] = useState(false);
  const navigate = useNavigate();

  return (
    <CareScreenerBackground>
      {cardAdded ? (
        <CareScreenerNarrowCard>
          <Flex style={{ padding: 20 }}>
            <Flex center>
              <img src={ThankYouImage} alt="Thank you" style={{ width: 200, height: 200, marginBottom: 10 }} />
            </Flex>
            <Text center theme="manaBlueDark" size={32} weight={700} style={{ paddingBottom: 20 }}>
              Thank you!
            </Text>
            <Text>Our billing team will reach out within 24 hours with your insurance coverage.</Text>
            <ButtonStack>
              <Button
                theme="manaBlue"
                style={{ marginTop: 20 }}
                title="Logout"
                onPress={() => navigate("/logout", { replace: true })}
              >
                Logout
              </Button>
            </ButtonStack>
          </Flex>
        </CareScreenerNarrowCard>
      ) : (
        <AddInsuranceCard
          required
          logEventParams={{
            source: "link"
          }}
          onContinuePress={setCardAdded}
        />
      )}
    </CareScreenerBackground>
  );
}
