import React from "react";
import { Text, View, TouchableOpacity } from "./react-native";
import Colors from "../theme/colors";

const styles = {
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    paddingLeft: 10,
    paddingRight: 10,
    height: 48,
    borderRadius: 12
  },
  buttonText: {
    display: "flex",
    textAlign: "center",
    fontSize: 18,
    fontWeight: "600"
  },
  buttonTextSmall: {
    display: "flex",
    textAlign: "center",
    fontSize: 16,
    fontWeight: "600"
  },
  disabled: {
    opacity: 0.5
  },
  hidden: {
    opacity: 0
  },
  disabledButton: {
    cursor: "default"
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center"
  },
  small: {
    display: "flex",
    height: 40,
    paddingTop: 7,
    paddingBottom: 7
  }
};

const themes = {
  white: {
    backgroundColor: "rgba(255,255,255,1)",
    color: "rgba(0,0,0,0.7)"
  },
  gray: {
    backgroundColor: "rgba(145,158,171,0.25)",
    color: "rgb(145,158,171)"
  },
  orange: {
    backgroundColor: "rgba(244,147,66,0.25)",
    color: "rgb(244,147,66)"
  },
  bloodOrange: {
    backgroundColor: "rgba(225, 95, 65, 0.25)",
    color: "rgb(225, 95, 65)"
  },
  red: {
    backgroundColor: "rgba(222,54,24,0.25)",
    color: "rgb(222,54,24)"
  },
  pink: {
    backgroundColor: "rgba(238, 140, 174, 0.25)",
    color: "rgba(238, 140, 174, 1)"
  },
  transparent: {
    backgroundColor: "rgba(255,255,255,0.5)",
    color: "rgba(255,255,255,1)"
  },
  brightSky: {
    backgroundColor: "rgba(28,195,244,0.25)",
    color: "rgb(28,195,244)"
  },
  nightSky: {
    backgroundColor: "rgba(62,44,217,0.25)",
    color: "rgb(62,44,217)"
  },
  green: {
    backgroundColor: "rgba(80,184,60,0.25)",
    color: "rgb(80,184,60)"
  },
  lemon: {
    backgroundColor: "rgba(238, 194, 0, 0.25)",
    color: "rgb(238, 194, 0)"
  },
  aquaMarine: {
    backgroundColor: "rgba(71,193,191,0.25)",
    color: "rgb(71,193,191)"
  },
  lightTeal: {
    backgroundColor: "rgba(211, 236, 236, 1)",
    color: "rgb(107, 190, 190)"
  },
  teal: {
    backgroundColor: "#3DC1D3",
    color: "#FFFFFF"
  },
  lightBlue: {
    backgroundColor: "#BFDEF2",
    color: "#007ACB"
  },
  blue: {
    backgroundColor: "rgba(92, 118, 244, 0.25)",
    color: "#5C76F4"
  },
  manaBlue: {
    backgroundColor: "#2562D8",
    color: "#FFFFFF"
  },
  manaBlueInverted: {
    color: "#2562D8",
    backgroundColor: "#FFFFFF"
  },
  manaBlueLight: {
    backgroundColor: Colors.MANA_BLUE_LIGHT,
    color: Colors.MANA_BLUE
  },
  goalReportCardRenew: {
    backgroundColor: "#D4ECEC",
    color: "#6EBEBE"
  },
  iconButton: {
    backgroundColor: "rgba(255,255,255,0.0)",
    color: "#000000"
  },
  purpleCoral: {
    backgroundColor: "#8d2995",
    color: "#FFFFFF"
  },
  manaYellow: {
    backgroundColor: "#F4D35E",
    color: "#1A2579"
  }
};

class Button extends React.PureComponent {
  render() {
    let theme = themes[this.props.theme] || themes.gray;
    let IconComponent = this.props.icon || this.props.iconLeft;
    return (
      <View
        style={[
          this.props.disabled ? (this.props.hidden ? styles.hidden : styles.disabled) : false,
          this.props.fullWidth && { width: "100%" }
        ]}
      >
        <TouchableOpacity
          style={[
            styles.buttonContainer,
            this.props.small ? styles.small : false,
            this.props.icon && { paddingLeft: 9, paddingRight: 9 },
            this.props.style ? this.props.style : false,
            { backgroundColor: this.props.style?.backgroundColor || theme.backgroundColor },
            (this.props.loading || this.props.disabled) && styles.disabledButton
          ]}
          activeOpacity={0.4}
          onPress={!this.props.disabled ? this.props.onPress : undefined}
          onPressIn={!this.props.disabled ? this.props.onPressIn : undefined}
          onPressOut={!this.props.disabled ? this.props.onPressOut : undefined}
          disabled={this.props.loading || this.props.disabled}
        >
          <View style={styles.row}>
            {this.props.iconLeft ? (
              <IconComponent size={this.props.iconSize || 18} color={theme.color} style={{ paddingRight: 5 }} />
            ) : null}
            {typeof this.props.title === "string" ? (
              <Text
                style={[styles.buttonText, { color: theme.color }, this.props.small ? styles.buttonTextSmall : false]}
              >
                {this.props.title}
              </Text>
            ) : (
              this.props.title && this.props.title()
            )}
            {this.props.icon && !this.props.iconLeft ? (
              <IconComponent size={this.props.iconSize || 18} color={theme.color} />
            ) : null}
          </View>
        </TouchableOpacity>
      </View>
    );
  }
}

export default Button;
