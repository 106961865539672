import React, { useCallback, useState } from "react";
import AppointmentScheduler from "@components/appointments/AppointmentScheduler";
import config from "@config";
import BookedConsult from "@components/appointments/BookedConsult";
import { Flex } from "@core";
import { logEvent } from "@actions";
import CareScreenerBackground from "@components/care-screener/CareScreenerBackground";
import { useSearchParams } from "react-router-dom";

export default function ScheduleConsult() {
  const [scheduledAppointment, setScheduledAppointment] = useState();
  const [searchParams] = useSearchParams();
  const [providerId] = useState(
    searchParams.get("providerId") ? parseInt(searchParams.get("providerId") || "") : undefined
  );

  const handleConsultScheduled = useCallback(appointment => {
    logEvent("consultScheduledFromLink");
    setScheduledAppointment(appointment);
  }, []);

  return (
    <CareScreenerBackground>
      {!scheduledAppointment ? (
        <AppointmentScheduler
          appointmentId={config.env.REACT_APP_CONSULT_APPT_TYPE_ID}
          appointmentUserId={undefined}
          appointmentContactMethod={"Phone Call"}
          availabilityRecurringInterval={undefined}
          availabilityRecurringCount={undefined}
          onAppointmentScheduled={handleConsultScheduled}
          careTeamMemberIds={providerId ? [providerId] : undefined}
          headerComponent={<Flex style={{ height: 20 }} />}
          style={{
            margin: "auto",
            marginTop: 30,
            marginBottom: 30,
            padding: 30
          }}
          schedulingQueryStartDate={undefined}
        />
      ) : (
        <BookedConsult appointment={scheduledAppointment} />
      )}
    </CareScreenerBackground>
  );
}
