import React, { useContext, useLayoutEffect, useMemo, useRef, useState } from "react";
import { useAtom } from "tiny-atom/react/hooks";
import { FamilyAssessmentContext } from "../../../context/FamilyAssessmentContext";
import moment from "moment";
import { ChevronLeft, ChevronRight } from "react-feather";
import Button from "../../Button";
import { StyleSheet, css } from "aphrodite";
import clipboard from "../../../assets/clipboard.png";
import { Select } from "@shopify/polaris";
import usePatientFamilyMembers from "../../../hooks/usePatientFamilyMembers";
import _ from "lodash";

const feelings = {
  green: ["happy", "excited", "grateful", "calm", "loved"],
  red: ["nervous", "annoyed", "sad", "stressed", "misunderstood", "angry"],
  yellow: ["meh"]
};

const choices = {
  green: [
    "great",
    "good",
    "amazing",
    "going well",
    "very helpful",
    "very much",
    "quite a bit",
    "extremely helpful",
    "really helpful",
    "helpful"
  ],
  yellow: ["meh", "it's okay", "rarely helpful", "maybe a little", "somewhat helpful"],
  red: ["not good", "disappointing", "not at all helpful", "not at all", "not that helpful"]
};

const valueSets = {
  green: new Set([...feelings.green, ...choices.green]),
  yellow: new Set([...feelings.yellow, ...choices.yellow]),
  red: new Set([...feelings.red, ...choices.red])
};

export default function WeeklySurvey() {
  const patient = useAtom(state => state.patient);
  const [currentSurvey, setCurrentSurvey] = useState(undefined);
  const currentIndexRef = useRef(0);
  const [selectedUser, setSelectedUser] = useState(patient);
  const { familyMembers } = usePatientFamilyMembers();

  const { completions } = useContext(FamilyAssessmentContext);

  const surveyResults = useMemo(() => {
    const identifier = selectedUser?.accountType === "PARENT" ? "manatee-parent-survey" : "bbk-client-survey";
    return (completions || []).filter(c => c.clientId === selectedUser?.id && c.assessmentIdentifier === identifier);
  }, [completions, selectedUser]);

  useLayoutEffect(() => {
    if (surveyResults?.length) {
      currentIndexRef.current = surveyResults.length - 1;
      setCurrentSurvey(surveyResults[currentIndexRef.current]);
    } else {
      currentIndexRef.current = 0;
      setCurrentSurvey(undefined);
    }
  }, [surveyResults]);

  const hasPrev = surveyResults.length && currentIndexRef.current > 0;
  const hasNext = surveyResults.length && currentIndexRef.current < surveyResults.length - 1;

  const handlePressPrev = () => {
    if (hasPrev) {
      const index = currentIndexRef.current - 1;
      currentIndexRef.current = index;
      setCurrentSurvey(surveyResults[index]);
    }
  };
  const handlePressNext = () => {
    if (hasNext) {
      const index = currentIndexRef.current + 1;
      currentIndexRef.current = index;
      setCurrentSurvey(surveyResults[index]);
    }
  };
  const containerStyles = currentSurvey ? styles.container : [styles.container, styles.containerNoResponses];

  const answersByQuestionId = _.groupBy(currentSurvey?.answers || [], a => a.questionId);
  const orderedQuestionIds = _.uniq((currentSurvey?.answers || []).map(a => a.questionId));

  return (
    <div className={css(containerStyles)}>
      <div className={css(styles.metadataContainer)}>
        <div className={css(styles.title)}>Weekly Survey</div>
        <div className={css(styles.selectContainer)}>
          <Select
            value={selectedUser?.id}
            options={familyMembers.map((u, i) => ({ label: u.firstName, value: u.id, key: i }))}
            onChange={selected => {
              setSelectedUser(familyMembers.find(u => u.id == selected));
            }}
          />
        </div>
        {currentSurvey && (
          <div className={css(styles.dateRow)}>
            <Button
              icon={ChevronLeft}
              iconSize={28}
              disabled={!hasPrev}
              hidden={!hasPrev}
              theme="iconButton"
              onPress={handlePressPrev}
            />
            <div className={css(styles.completionDate)}>{moment(currentSurvey.completedAt).format("LL")}</div>
            <Button
              icon={ChevronRight}
              iconSize={28}
              disabled={!hasNext}
              hidden={!hasNext}
              theme="iconButton"
              onPress={handlePressNext}
            />
          </div>
        )}
        <img className={css(styles.image)} src={clipboard} alt="clipboard" />
      </div>
      {currentSurvey && (
        <div className={css(styles.answersContainer)}>
          {orderedQuestionIds.map(questionId => {
            const answers = answersByQuestionId[questionId];
            return (
              <div key={questionId}>
                <div className={css(styles.question)}>{answers[0].questionText}</div>
                <div className={css(answers[0]?.choiceText ? styles.answerRow : styles.openTextAnswerRow)}>
                  {answers.map((answer, index) => (
                    <span key={`${questionId}.${index}`} className={css([getChoiceStyle(answer)])}>
                      {answer.choiceText || answer.responseText || "(Skipped)"}
                    </span>
                  ))}
                </div>
              </div>
            );
          })}
        </div>
      )}
      {!currentSurvey && (
        <div className={css(styles.noResponses)}>No survey results from {selectedUser?.firstName || ""} yet</div>
      )}
    </div>
  );
}

const getChoiceStyle = answer => {
  if (answer.choiceText) {
    const choiceLower = answer.choiceText.toLowerCase();
    let colorStyle = styles.teal;
    if (valueSets.green.has(choiceLower)) {
      colorStyle = styles.green;
    } else if (valueSets.yellow.has(choiceLower)) {
      colorStyle = styles.yellow;
    } else if (valueSets.red.has(choiceLower)) {
      colorStyle = styles.red;
    }
    return [styles.choice, colorStyle];
  }
  return [styles.openTextAnswer];
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#E5EEFF",
    borderRadius: 8,
    padding: 20,
    margin: 20,
    marginBottom: 10,
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start"
  },
  containerNoResponses: {
    height: 230
  },
  title: {
    color: "#1A2579",
    fontWeight: 700,
    fontSize: 24,
    marginTop: 10,
    marginBottom: 10
  },
  selectContainer: {
    margin: 10
  },
  dateRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  metadataContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    width: 200
  },
  completionDate: {
    width: 120,
    textAlign: "center",
    color: "#1A2579",
    fontWeight: 700
  },
  answersContainer: {
    marginLeft: 20,
    marginRight: 20,
    minWidth: 230,
    justifyContent: "center",
    overflow: "hidden",
    flexDirection: "column"
  },
  question: {
    color: "#000000",
    fontWeight: 700,
    marginTop: 10
  },
  answerRow: {
    flexDirection: "row",
    justifyContent: "flex-start",
    marginTop: 5,
    flex: 1,
    wordBreak: "break-word"
  },
  openTextAnswerRow: {
    flexDirection: "row",
    justifyContent: "flex-start",
    borderLeftColor: "#2562D8",
    borderLeftWidth: 3,
    borderLeftStyle: "solid",
    paddingLeft: 10,
    marginTop: 5,
    flex: 1,
    wordBreak: "break-word"
  },
  image: {
    height: 80,
    width: 80
  },
  noResponses: {
    alignSelf: "center",
    textAlign: "center",
    fontWeight: 500,
    paddingTop: 15,
    flex: 1
  },
  red: {
    backgroundColor: "#ff2000"
  },
  yellow: {
    backgroundColor: "#ffbe05"
  },
  green: {
    backgroundColor: "#52d315"
  },
  teal: {
    backgroundColor: "#3BC1D2"
  },
  choice: {
    width: "max-content",
    color: "#FFFFFF",
    fontFamily: "Helvetica, sans-serif",
    fontWeight: 600,
    fontSize: 14,
    lineHeight: 2,
    padding: "2px 8px 2px 8px",
    marginRight: 10,
    whiteSpace: "nowrap"
  },
  openTextAnswer: {
    fontSize: 14
  }
});
