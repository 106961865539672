import React from "react";
import useCareScreenerWidth from "../../hooks/care-screener/useCareScreenerWidth";
import Flex from "../core/Flex";

type Props = {
  children?: React.ReactNode;
  smallScreenWidth?: string;
  smallScreenWidthTrigger?: number;
  largScreenMinWidth?: number;
};

export default function CareScreenerCard({
  children,
  smallScreenWidth,
  smallScreenWidthTrigger,
  largScreenMinWidth
}: Props) {
  const { widthStyle } = useCareScreenerWidth({
    smallScreenWidth: smallScreenWidth || "100%",
    smallScreenWidthTrigger: smallScreenWidthTrigger || 500,
    largScreenMinWidth: largScreenMinWidth || 500
  });

  return (
    <Flex
      style={{
        ...widthStyle,
        flexShrink: 1,
        marginTop: "5vh",
        marginBottom: "5vh",
        borderRadius: 20,
        backgroundColor: "#FFFFFF",
        padding: "2%"
      }}
    >
      {children}
    </Flex>
  );
}
