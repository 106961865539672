import { useQuery } from "@apollo/client";
import { GET_FAMILY_MEMBERS } from "../queries";
import { useEffect, useMemo } from "react";
import { useGlobalContext } from "../context/GlobalComponentsContext";

export default function useFamilyMembers() {
  const { data, loading, error } = useQuery(GET_FAMILY_MEMBERS);
  const { showError } = useGlobalContext();

  useEffect(() => {
    if (error) {
      reportError(error);
      showError();
    }
  }, [error, showError]);

  const familyMembers = useMemo(() => data?.familyMembers || [], [data]);

  return { familyMembers, loading };
}
