import React, { MutableRefObject, Ref, useCallback, useEffect } from "react";
import { useRef } from "react";
import { FileData, toAttachment } from "@utils/fileUtils";
import { useGlobalContext } from "@context/GlobalComponentsContext";

export type FilePickerRef = {
  openFilePicker: () => void;
};

export type FilePickerProps = {
  onFileSelected: (file: FileData) => void;
  acceptedFileTypes?: string;
  allowCameraCapture?: boolean;
  maxSizeInMb?: number;
  pickerRef: MutableRefObject<FilePickerRef | undefined>;
};

export default function FilePicker({
  onFileSelected,
  acceptedFileTypes = "image/*,video/*,.pdf,.doc,.docx",
  maxSizeInMb = 20,
  allowCameraCapture = false,
  pickerRef
}: FilePickerProps) {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { showError } = useGlobalContext();

  const handleChange = async event => {
    if (event?.target?.files) {
      const file = event.target.files[0];
      if (file) {
        if (file.size && file.size > maxSizeInMb * 1024 * 1024) {
          showError(`File size exceeds ${maxSizeInMb} MB. Please select a smaller file.`);
        } else {
          const fileData = await toAttachment(file);
          onFileSelected(fileData);
        }
      }
    }
  };

  const openFilePicker = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  }, []);

  useEffect(() => {
    if (pickerRef) {
      pickerRef.current = { openFilePicker };
    }
  }, [openFilePicker, pickerRef]);

  const captureProps: React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > = allowCameraCapture ? { capture: "environment" } : {};

  return (
    <input
      hidden={true}
      ref={inputRef}
      type="file"
      accept={acceptedFileTypes}
      {...captureProps}
      onChangeCapture={handleChange}
    />
  );
}
