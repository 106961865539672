import React, { useContext } from "react";
import CareScreenerProgress from "../components/care-screener/CareScreenerProgress";
import ScheduleAppointment from "./ScheduleAppointment";
import useCareScreeners from "../hooks/useCareScreeners";
import { UserContext } from "../context/UserContext";
import Spinner from "../components/Spinner";
import moment from "moment";

export default function ScheduleIntake() {
  const { user } = useContext(UserContext) || {};
  const { careScreeners, loading } = useCareScreeners(user?.id, true);

  if (loading) {
    return <Spinner />;
  }
  const schedulingQueryStartDate = moment(careScreeners?.at(-1)?.schedulingQueryStartDate)
    .add(1, "day")
    .startOf("day")
    .toISOString();

  return (
    <ScheduleAppointment
      availabilityRecurringCount={3}
      schedulingQueryStartDate={schedulingQueryStartDate}
      availabilityRecurringInterval="Weekly"
      header={<CareScreenerProgress currentView="scheduleConsult" />}
    />
  );
}
