
export const screenerViews = {
  typeform: "typeform",
  insuranceEligibility: "insuranceEligibility",
  passwordInput: "passwordInput",
  scheduleConsult: "scheduleConsult",
  consultBooked: "consultBooked",
  intakeConfirmation: "intakeConfirmation",
  loadingAccountCreation: "loadingAccountCreation",
  nextSteps: "nextSteps",
  directIntakeRequested: "directIntakeRequested",
  providerSelection: "providerSelection",
  addInsuranceCard: "addInsuranceCard",
};

export default screenerViews;
