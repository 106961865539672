import React, { useEffect, useMemo, useState } from "react";
import { useAtom } from "tiny-atom/react/hooks";
import "./MessagesTab.css";
import Conversation from "./Conversation";
import _ from "lodash";
import Button from "../Button";
import { Edit } from "react-feather";
import NewConversationModal from "./NewConversationModal";
import {
  getDisplayLabelFromComversation,
  getIconFromConversation,
  getTypeLabelFromConversaton,
  getParentsLabel,
  getClientsLabel
} from "../../utils/messagesUtils";
import { Component, ComponentSwitch } from "../tabs/ComponentSwitch";
import { Calendar } from "react-feather";
import useCareTeam from "../../hooks/useCareTeam";
import LinkedCTM from "../../assets/linked-ctm.png";
import usePatientFamilyMembers from "../../hooks/usePatientFamilyMembers";

const defaultTab = "family";

export default function MessagesTab({ conversations, refetch }) {
  const patient = useAtom(state => state.patient);
  const userId = useAtom(state => state.userId);
  const [selectedConversation, setSelectedConversation] = useState(undefined);
  const [showNewConversationModal, setShowNewConversationModal] = useState(false);
  const [newConversationId, setNewConversationId] = useState(undefined);
  const [tabSelection, setTabSelection] = useState(defaultTab);

  const dmConversations = useMemo(
    () => (conversations || [])?.filter(convo => convo.type === "DIRECT_MESSAGE"),
    [conversations]
  );

  const familyConversations = useMemo(
    () => (conversations || []).filter(c => c.familyId && c.familyId === patient.familyId),
    [conversations, patient.familyId]
  );

  const { careCoordinationEnabled } = useCareTeam({ skip: false });
  const { familyMembers } = usePatientFamilyMembers();

  useEffect(() => {
    if (dmConversations?.length > 0 && !selectedConversation) {
      const conversation = dmConversations[0];
      setSelectedConversation(conversation);
    }
  }, [dmConversations, selectedConversation, userId]);

  useEffect(() => {
    if (!!newConversationId && dmConversations) {
      const newConversation = dmConversations.find(convo => convo.id === newConversationId);
      if (newConversation) {
        setNewConversationId(undefined);
        setSelectedConversation(newConversation);
      }
    }
  }, [newConversationId, dmConversations]);

  const careCoordinationExternalConversation = useMemo(() => {
    return familyConversations.find(convo => convo.type === "CARE_TEAM_EXTERNAL");
  }, [familyConversations]);

  const careCoordinationInternalConversation = useMemo(() => {
    return familyConversations.find(convo => convo.type === "CARE_TEAM_INTERNAL");
  }, [familyConversations]);

  useEffect(() => {
    if (selectedConversation?.type === "CARE_TEAM_INTERNAL") {
      setTabSelection("internal");
    } else {
      setTabSelection("family");
    }
  }, [selectedConversation]);

  const conversation = useMemo(
    () => selectedConversation || careCoordinationExternalConversation,
    [careCoordinationExternalConversation, selectedConversation]
  );

  const tabComponents = useMemo(
    () => ({
      internal: (
        <>
          <div className="conversations-list">Manatee Care Team</div>
          {careCoordinationInternalConversation?.users.map((user, i) => {
            return (
              <div className="direct-message-line-item" key={i}>
                <img className="linked-ctm-icon" src={LinkedCTM} />
                <div className="direct-message-label">
                  {user.firstName} {user.lastName}
                </div>
              </div>
            );
          })}
        </>
      ),
      family: (
        <>
          {careCoordinationExternalConversation && careCoordinationEnabled && (
            <>
              <div className="conversations-list">Your Conversations</div>
              <div
                className={`direct-message-line-item line-item-clickable ${
                  conversation && _.isEqual(conversation.id, careCoordinationExternalConversation.id) ? "selected" : ""
                }`}
                onClick={() => {
                  setSelectedConversation(careCoordinationExternalConversation);
                }}
              >
                <Calendar color="#2562d8" />
                <div className="care-team-title-container">
                  <div className="care-team-row-title">
                    {getDisplayLabelFromComversation(careCoordinationExternalConversation)}
                  </div>
                  <div className="care-team-row-subtitle">
                    {getTypeLabelFromConversaton(careCoordinationExternalConversation)}
                  </div>
                </div>
                {careCoordinationExternalConversation.read === false && <div className="direct-message-unread"></div>}
              </div>
            </>
          )}
          <div className="conversations-list">
            Direct Messages
            <Button
              small
              theme="manaBlue"
              icon={Edit}
              style={styles.newConversation}
              onPress={() => {
                setShowNewConversationModal(true);
              }}
            />
          </div>
          {dmConversations?.length === 0 && (
            <div className="direct-messages-empty">
              Start a conversation with {patient.firstName} or their caregivers
            </div>
          )}
          {dmConversations?.map((convo, i) => {
            return (
              <div
                key={i}
                className={`direct-message-line-item line-item-clickable ${
                  conversation && _.isEqual(conversation.id, convo.id) ? "selected" : ""
                }`}
                onClick={() => {
                  setSelectedConversation(convo);
                }}
              >
                <img src={getIconFromConversation(convo)} className="direct-message-icon" />
                <div className="direct-message-label">{getDisplayLabelFromComversation(convo, userId)}</div>
                {convo?.read === false && <div className="direct-message-unread"></div>}
              </div>
            );
          })}
        </>
      )
    }),
    [
      careCoordinationEnabled,
      careCoordinationExternalConversation,
      careCoordinationInternalConversation?.users,
      conversation,
      dmConversations,
      patient?.firstName,
      userId
    ]
  );

  const conversationTabs = useMemo(
    () => [
      Component({
        text: "Family",
        onPress: () => {
          setSelectedConversation(careCoordinationExternalConversation);
        }
      }),
      Component({
        text: "Internal",
        onPress: () => {
          setSelectedConversation(careCoordinationInternalConversation);
        }
      })
    ],
    [careCoordinationExternalConversation, careCoordinationInternalConversation]
  );

  return (
    <div className="messages">
      <div className="contacts-list-container">{tabComponents[tabSelection]}</div>
      <div className="conversation-container">
        {careCoordinationEnabled && careCoordinationExternalConversation && careCoordinationInternalConversation && (
          <ComponentSwitch
            tabs={conversationTabs}
            defaultIndex={0}
            selectedTheme={tabSelection === "internal" ? "purpleCoral" : "manaBlue"}
          />
        )}
        {conversation !== undefined && (
          <div className="conversation-component-container">
            <Conversation
              conversationId={conversation.id}
              conversation={conversation}
              asUserId={userId}
              label={getDisplayLabelFromComversation(conversation, userId)}
              typeLabel={getTypeLabelFromConversaton(conversation)}
              clientsLabel={getClientsLabel([patient])}
              parentsLabel={getParentsLabel(familyMembers)}
            />
          </div>
        )}
      </div>
      <NewConversationModal
        hidden={!showNewConversationModal}
        conversations={dmConversations}
        onClose={() => {
          setShowNewConversationModal(false);
        }}
        createdCallback={async conversationId => {
          await refetch();
          setNewConversationId(conversationId);
        }}
      />
    </div>
  );
}

const styles = {
  newConversation: {
    paddingLeft: 20,
    paddingRight: 20,
    height: 42,
    borderRadius: 21
  }
};
