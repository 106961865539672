import React from "react";
import { Flex } from "@core";

type Props = { children: React.ReactNode };

export default function CareScreenerNarrowCard({ children }: Props) {
  return (
    <Flex flex fullWidth center>
      <Flex
        style={{
          borderRadius: 20,
          backgroundColor: "#FFFFFF",
          padding: "2%",
          maxWidth: 400,
          position: "relative"
        }}
      >
        {children}
      </Flex>
    </Flex>
  );
}
