import React, { useEffect, useRef, useState } from "react";
import { Flex, Text } from "@core";
import { createStyleSheet, TouchableOpacity } from "@components/react-native";
import { logEvent } from "@actions";
import UploadIcon from "@assets/icons/upload-gray.svg";
import { FileData } from "@utils/fileUtils";
import { XIcon } from "@shopify/polaris-icons";
import { Icon } from "@shopify/polaris";
import FilePicker, { FilePickerRef } from "@components/system/FilePicker";

type AddPhotoProps = {
  label: string;
  newFileName: string;
  onFileChange?: (file: FileData | null) => void;
  cameraContainerStyle?: React.CSSProperties;
};

export default function PhotoUploader({ label, onFileChange }: AddPhotoProps) {
  const [fileData, setFileData] = useState<FileData | null>(null);
  const filePickerRef = useRef<FilePickerRef>();

  useEffect(() => {
    if (onFileChange) {
      onFileChange(fileData);
    }
  }, [fileData, onFileChange]);

  return (
    <>
      <Flex flex fullWidth>
        <Text theme="manaBlueDark" size={14} weight={700}>
          {label}
        </Text>
        <Flex row style={{ marginTop: 10, marginBottom: 5 }}>
          {fileData ? (
            <Flex row spaceBetween fullWidth style={styles.button}>
              <Text>{fileData.name}</Text>
              <TouchableOpacity
                style={styles.cancel}
                onPress={() => {
                  setFileData(null);
                }}
              >
                <Icon source={XIcon} />
              </TouchableOpacity>
            </Flex>
          ) : (
            <Flex fullWidth flex row>
              <AddPhotoButton
                label="upload photo"
                icon={<img src={UploadIcon} style={styles.buttonIcon} />}
                onPress={() => {
                  filePickerRef.current?.openFilePicker();
                }}
              />
            </Flex>
          )}
        </Flex>
      </Flex>
      <FilePicker
        pickerRef={filePickerRef}
        acceptedFileTypes="image/*"
        allowCameraCapture
        onFileSelected={file => {
          logEvent("insuranceCardFileSelected", { label });
          setFileData(file);
        }}
      />
    </>
  );
}

function AddPhotoButton({ icon, label, onPress }) {
  return (
    <TouchableOpacity style={styles.button} onPress={onPress}>
      <Flex flex row center>
        {icon}
        <Text>{label}</Text>
      </Flex>
    </TouchableOpacity>
  );
}

const styles = createStyleSheet({
  image: {
    width: 200,
    height: 200,
    margin: 10,
    objectFit: "cover"
  },
  button: {
    padding: 15,
    borderRadius: 12,
    display: "flex",
    flexDirection: "row",
    flex: 1,
    backgroundColor: "#F2F2F2"
  },
  buttonIcon: {
    width: 30,
    height: 30,
    paddingRight: 5
  },
  cancel: {
    marginLeft: 10,
    width: 40,
    right: -10
  }
});
