import React, { useCallback, useContext, useMemo } from "react";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { AppointmentSchedulerContext } from "./AppointmentScheduler";
import moment from "moment-timezone";
import { difference } from "lodash";

export default function AppointmentDayPicker({ selectedDay, setSelectedDay }) {
  const { availableSlots } = useContext(AppointmentSchedulerContext)!;
  const availableDays = Object.keys(availableSlots);

  const unavailableDays = useMemo(() => {
    const startOfMonth = moment(selectedDay).startOf("month");
    const endOfMonth = moment(selectedDay).endOf("month");

    const allDatesInMonth: string[] = [];
    const currentDate = startOfMonth.clone();
    while (currentDate.isSameOrBefore(endOfMonth)) {
      allDatesInMonth.push(currentDate.format("YYYY-MM-DD"));
      currentDate.add(1, "day");
    }

    const unavailableDaysStrings = difference(allDatesInMonth, availableDays);

    return unavailableDaysStrings.map(day => moment(day).toDate());
  }, [availableDays, selectedDay]);

  const onMonthChangeHandler = useCallback(
    (targetDate: Date) => {
      const targetMonth = moment(targetDate);
      const firstAvailableDateInMonth = availableDays.find(day => targetMonth.isSame(day, "month"));

      if (firstAvailableDateInMonth) {
        setSelectedDay(moment(firstAvailableDateInMonth).toDate());
      } else {
        setSelectedDay(targetDate);
      }
    },
    [availableDays, setSelectedDay]
  );

  return (
    <div className="appointment-date-picker-container">
      <DatePicker
        inline
        useWeekdaysShort={true}
        onMonthChange={onMonthChangeHandler}
        onChange={e => setSelectedDay(e)}
        selected={selectedDay}
        excludeDates={unavailableDays}
      />
    </div>
  );
}
