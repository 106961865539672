import moment from "moment";
import momentTimeZone from "moment-timezone";
import parser from "cron-parser";

export const CUSTOM = "59 59 23 * * ";
export const DAILY = "59 59 23 * * *";

const MONDAY_FIRST = [6, 0, 1, 2, 3, 4, 5];
const MONTH_STRINGS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

const timeZoneAbbreviations = {
  EST: "Eastern Standard Time",
  EDT: "Eastern Daylight Time",
  CST: "Central Standard Time",
  CDT: "Central Daylight Time",
  MST: "Mountain Standard Time",
  MDT: "Mountain Daylight Time",
  PST: "Pacific Standard Time",
  PDT: "Pacific Daylight Time"
};

export function addDays(date, days) {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

function leapYear(year) {
  return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
}

export function getNumberOfDaysInMonth(monthIndex, year) {
  switch (monthIndex) {
    case 0:
      return 31;
    case 1:
      return leapYear(year) ? 29 : 28;
    case 2:
      return 31;
    case 3:
      return 30;
    case 4:
      return 31;
    case 5:
      return 30;
    case 6:
      return 31;
    case 7:
      return 31;
    case 8:
      return 30;
    case 9:
      return 31;
    case 10:
      return 30;
    case 11:
      return 31;

    // no default
  }
}

function getWeekdayNames() {
  return ["S", "M", "T", "W", "T", "F", "S"];
}

export function getDayOfWeek(dateInQuestion) {
  const numericDay = dateInQuestion.getDay();
  if (new Date().getDay() === numericDay) {
    return "Today";
  }
  if (new Date().getDay() - numericDay === 1) {
    return "Yesterday";
  }

  return moment(dateInQuestion).format("dddd, MM/DD");
}

export function getDayNames(firstDayMonday) {
  const days = getWeekdayNames();
  if (firstDayMonday) {
    const sunday = days.shift();
    days.push(sunday);
    return days;
  }

  return days;
}

export function getDaysOfMonth(monthNumber, year, firstDayMonday) {
  const startDayOfMonth = moment([year, monthNumber]);
  const daysToAdd = getNumberOfDaysInMonth(monthNumber, year);

  const days = [];

  const startWeekOffset = firstDayMonday ? MONDAY_FIRST[startDayOfMonth.day()] : startDayOfMonth.day();
  const firstMonthDay = startDayOfMonth.toDate();
  const daysToCompleteRows = (startWeekOffset + daysToAdd) % 7;
  const lastRowNextMonthDays = daysToCompleteRows ? 7 - daysToCompleteRows : 0;

  for (let i = -startWeekOffset; i < daysToAdd + lastRowNextMonthDays; i++) {
    const date = addDays(firstMonthDay, i);
    const day = date.getDate();
    const month = date.getMonth() + 1; // + 1 because moment returns zero-based and we want date-like
    const fullDay = day < 10 ? `0${day}` : day;
    const fullMonth = month < 10 ? `0${month}` : month;
    const id = `${date.getFullYear()}-${fullMonth}-${fullDay}`;
    const isOnSelectedRange = true;
    const isOutOfRange = false;
    const isMonthDate = i >= 0 && i < daysToAdd;
    days.push({
      id: id,
      date,
      isMonthDate,
      isOutOfRange,
      isVisible: isOnSelectedRange && isMonthDate
    });
  }

  return days;
}

export function getMonth(date) {
  const year = date.getFullYear();
  const monthNumber = date.getMonth();
  return {
    id: `${year}-${monthNumber}`,
    monthNumber,
    year,
    name: `${MONTH_STRINGS[monthNumber]} ${year}`
  };
}

export function occurredSincePreviousInterval(date, cron) {
  const lastInterval = new Date(parseCronToPrevious(cron));
  return new Date(date).getTime() > lastInterval.valueOf();
}

function parseCronToPrevious(cron) {
  let interval = parser.parseExpression(cron);
  return interval.prev();
}

export function isExpired(date) {
  return new Date(date).getTime() - new Date().getTime() < 0;
}

export function agoGenerality(date) {
  date = new Date(date);
  const diff = date.getTime() - new Date().getTime();
  if (diff > 0) {
    return "";
  }

  if (isToday(date)) {
    return "today";
  }

  let daysAgo = Math.floor((diff * -1) / 86400000);
  if (daysAgo === 1) {
    return "yesterday";
  } else if (daysAgo < 7) {
    return "in the past week";
  } else if (daysAgo < 31) {
    // doesn't consider specific month
    return "in the past month";
  } else {
    return "over a month ago";
  }
}

function isToday(someDate) {
  const today = new Date();
  const isToday =
    someDate.getDate() === today.getDate() &&
    someDate.getMonth() === today.getMonth() &&
    someDate.getFullYear() === today.getFullYear();
  return isToday;
}

export function getEndOfDays(days) {
  const date = moment(new Date()).add(days, "days").hour(23).minute(59).seconds(59).milliseconds(0);
  return date.toISOString();
}

export function pointsSince(daysAgo, achievements) {
  const sinceDate = moment().subtract(daysAgo, "days");
  let points = 0;

  for (let i = 0; i < achievements.length; i++) {
    let achievementDate = moment(achievements[i].achievedAt);

    if (sinceDate.to(achievementDate).includes("in")) {
      points += achievements[i].points;
    }
  }

  return points;
}

export function pointDiffSince(daysAgo, achievements, isStat = false) {
  const sinceDate = moment().subtract(daysAgo, "days");
  const compareDate = moment().subtract(2 * daysAgo, "days");
  let newPoints = 0;
  let prevPoints = 0;

  for (let i = 0; i < achievements.length; i++) {
    let achievementDate = moment(achievements[i].achievedAt);

    if (sinceDate.to(achievementDate).includes("in")) {
      newPoints += achievements[i].points;
    } else if (compareDate.to(achievementDate).includes("in")) {
      prevPoints += achievements[i].points;
    }
  }

  if (isStat) {
    if (!prevPoints) {
      return 100;
    }
    if (!newPoints) {
      return -100;
    }
    return 100 * (1 - prevPoints / newPoints);
  } else {
    return newPoints - prevPoints;
  }
}

function timeOfDayToMinutes(time) {
  const chunks = time.split(":");
  const minutes = parseInt(chunks[0]) * 60 + parseInt(chunks[1]);
  return minutes;
}

function minutesToTimeOfDay(minutes) {
  if (minutes >= 0 && minutes < 60) {
    // Midnight to 12:59 AM
    return `00:${minutes}`;
  } else if (minutes > 60) {
    // 01:00 AM or later
    let hours = Math.floor(minutes / 60);
    const mins = minutes - hours * 60;
    if (hours >= 24) {
      hours -= 24;
    }
    const hoursString = hours < 10 ? `0${hours}` : hours;
    const minutesString = mins < 10 ? `0${mins}` : mins;
    return `${hoursString}:${minutesString}`;
  } else {
    // < 12:00 AM (previous day)
    const positiveMinutes = Math.abs(minutes);
    const hoursBack = Math.ceil(positiveMinutes / 60);
    const mins = Math.abs(positiveMinutes - hoursBack * 60);
    const hours = 24 - hoursBack;
    const hoursString = hours < 10 ? `0${hours}` : hours;
    const minutesString = mins < 10 ? `0${mins}` : mins;
    return `${hoursString}:${minutesString}`;
  }
}

export function getGMT(date) {
  const hours = date.getHours() < 10 ? "0" + date.getHours().toString() : date.getHours();
  const minutes = date.getMinutes() < 10 ? "0" + date.getMinutes().toString() : date.getMinutes();
  const time = `${hours}:${minutes}`;
  const offsetInMins = new Date().getTimezoneOffset();
  const timeInMins = timeOfDayToMinutes(time);
  const gmtMins = timeInMins + offsetInMins;
  const gmtTime = minutesToTimeOfDay(gmtMins);
  return gmtTime;
}

export function getLocalTime(gmtTime) {
  const offsetInMins = new Date().getTimezoneOffset();
  const gmtTimeInMins = timeOfDayToMinutes(gmtTime);
  let localMins = gmtTimeInMins - offsetInMins;
  const localTime = minutesToTimeOfDay(localMins);
  const date = new Date(`July 1, 1999 ${localTime}`);
  return date;
}

export function getTimeZone() {
  return momentTimeZone.tz.guess();
}

export function getTimeZoneOffset() {
  return moment().format("Z");
}

export function getTimeZoneName() {
  const abr = moment.tz(getTimeZone()).format("z");
  return timeZoneAbbreviations[abr] || abr;
}
