import { ApolloError, useQuery } from "@apollo/client";
import { useEffect } from "react";
import { GET_CARE_SCREENERS } from "../queries";
import { CareScreenerData } from "../model/careScreenerModels";

export default function useCareScreeners(
  userId?: number,
  filterByLatest: boolean = false
): { careScreeners: CareScreenerData[]; loading: boolean; error: ApolloError | undefined } {
  const { data, loading, error } = useQuery(GET_CARE_SCREENERS, {
    variables: {
      userId,
      filterByLatest
    },
    fetchPolicy: "cache-and-network"
  });

  useEffect(() => {
    if (error) {
      reportError(error);
    }
  }, [error]);

  return { careScreeners: data?.careScreeners, loading, error };
}
