import { isString } from "lodash";
import { reportError } from "./errorUtils";

export type FileData = {
  name: string;
  mimeType: string;
  base64: string;
};

export async function toAttachment(file: File): Promise<FileData> {
  const dataUrl = await readAsBase64DataUrl(file);
  if (dataUrl !== null && isString(dataUrl)) {
    const base64 = dataUrl.split(",")[1];
    return {
      name: file.name,
      mimeType: file.type,
      base64
    };
  } else {
    reportError(`Failed to read file base64 data URL with type ${typeof dataUrl}`);
    throw new Error("Failed to load file");
  }
}

export function fromDataUrl(dataUrl: string, fileName: string): FileData {
  const base64 = dataUrl.split(",")[1];
  const mimeType = dataUrl.split(";")[0].split(":")[1];
  return {
    name: fileName,
    mimeType,
    base64
  };
}

export function toDataUrl(attachment: FileData) {
  return `data:${attachment.mimeType};base64,${attachment.base64}`;
}

function readAsBase64DataUrl(file: File): Promise<FileReader["result"]> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}
