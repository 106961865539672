import React, { useCallback, useContext, useState } from "react";
import { StyleSheet, css } from "aphrodite";
import ManateeModal from "../Modal";
import { FamilyAssessmentContext, getAssessmentsCtmPermittedToAssign } from "../../context/FamilyAssessmentContext";
import usePatientFamilyMembers from "../../hooks/usePatientFamilyMembers";
import { Checkbox } from "@shopify/polaris";
import { useAtom, useActions } from "tiny-atom/react/hooks";
import Button from "../Button";
import ChartIcon from "../../assets/line-chart-icon-teal.png";
import { useGlobalContext } from "../../context/GlobalComponentsContext";
import { useMutation } from "@apollo/client";
import { ASSIGN_ASSESSMENTS } from "../../queries";
import { AppContext } from "../../context/AppContext";
import OverlayCenteredView from "../OverlayCenteredView";
import "./AssignAssessmentModal.css";

type AssignmentRequest = {
  assigneeId: number;
  assessmentIdentifier: string;
  assessmentParameters?: { subjectUserId: number };
};

export default function AssignAssessmentModal({ hide }: { hide: () => void }) {
  const patientData = useAtom(state => state.patientData);

  const child = patientData?.childDetails;

  const { showError } = useGlobalContext();
  const { displayToast } = useActions();
  const {
    assignments,
    assignable: familyAssignability,
    refetch: refetchAssignments,
    loading: loadingAssignments
  } = useContext(FamilyAssessmentContext);

  const { parents }: { parents: { id: number; firstName: string; lastName: string }[] } = usePatientFamilyMembers();
  const { user } = useContext(AppContext);

  const [loading, setLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState<{ userId: number; assessmentIdentifiers: string[] }[]>([]);
  const assignableAssessmentsByCtm = getAssessmentsCtmPermittedToAssign(user, child);

  const [createAssignmentsMutation] = useMutation(ASSIGN_ASSESSMENTS);

  const handleAssignAssessments = async () => {
    try {
      setLoading(true);

      const assignments: AssignmentRequest[] = selectedRows.flatMap(row =>
        row.assessmentIdentifiers.map(identifier => {
          let assessmentParameters: undefined | { subjectUserId: number } = undefined;
          if (identifier === "psc") {
            assessmentParameters = {
              subjectUserId: child?.id
            };
          }
          return { assigneeId: row.userId, assessmentIdentifier: identifier, assessmentParameters };
        })
      );

      if (assignments.length) {
        await createAssignmentsMutation({
          variables: {
            assignments
          }
        });
        displayToast({ text: "Assessments sent" });
      }
    } catch (error) {
      showError();
    } finally {
      await refetchAssignments();
      setLoading(false);
    }
  };

  const AssignmentRow = useCallback(
    ({ user, assessmentIdentifiers }) => {
      const assigned = assignments.some(
        a =>
          a.assigneeId === user?.id &&
          assessmentIdentifiers.includes(a.assessmentIdentifier) &&
          (a.assessmentParameters?.subjectUserId === undefined || a.assessmentParameters?.subjectUserId === child?.id)
      );

      const assignableForUser = assessmentIdentifiers.every(identifier =>
        familyAssignability?.userAssessments?.some(
          a => a.userId === user?.id && a.assessmentIdentifiers?.includes(identifier)
        )
      );

      const assignableByCtm = assessmentIdentifiers.every(identifier =>
        assignableAssessmentsByCtm.includes(identifier)
      );

      if (!user) {
        return <></>;
      }

      const disabled = assigned || !assignableByCtm || !assignableForUser || loading || loadingAssignments;
      const name = `${user.firstName} ${user.lastName ? user.lastName : ""}`;
      return (
        <>
          <div className={css(styles.rowSpaceBetween, styles.bottomBorder)}>
            <div className={css(styles.row)}>
              <Checkbox
                label={undefined}
                labelHidden={true}
                disabled={disabled}
                checked={selectedRows?.some(
                  r => r.userId === user.id && r.assessmentIdentifiers.includes(assessmentIdentifiers[0])
                )}
                onChange={checked => {
                  if (checked) {
                    setSelectedRows([...selectedRows, { userId: user.id, assessmentIdentifiers }]);
                  } else {
                    setSelectedRows(selectedRows.filter(r => r.userId !== user.id));
                  }
                }}
              />
              <p className={css(disabled ? styles.disabledColor : styles.name)}>{name}</p>
            </div>
            {assigned ? (
              <div className={css(styles.statusLabelContainer, styles.assignedColor)}>
                <p className={css(styles.statusLabel)}>currently assigned</p>
              </div>
            ) : !assignableForUser ? (
              <div className={css(styles.statusLabelContainer, styles.notApplicableColor)}>
                <p className={css(styles.statusLabel)}>not applicable</p>
              </div>
            ) : undefined}
          </div>
        </>
      );
    },
    [
      assignments,
      loading,
      loadingAssignments,
      selectedRows,
      child?.id,
      familyAssignability?.userAssessments,
      assignableAssessmentsByCtm
    ]
  );

  const assignButtonDisabled = !selectedRows.length || loading || loadingAssignments;

  return (
    <OverlayCenteredView>
      <ManateeModal center onPressExit={hide}>
        <div>
          <div className={css(styles.row)}>
            <img alt="chart" src={ChartIcon} className={css(styles.icon)} />
            <div className={css(styles.headerText)}>Send assessment</div>
          </div>
          {parents?.length > 0 && (
            <div>
              <div className={css(styles.assessment, styles.bottomBorder)}>PSC</div>
              {parents.map(parent => (
                <AssignmentRow key={`${parent?.id}-psc`} user={parent} assessmentIdentifiers={["psc"]} />
              ))}
            </div>
          )}
          <div>
            <div className={css(styles.assessment, styles.bottomBorder)}>PHQ-8 and GAD-7</div>
            {child && <AssignmentRow user={child} assessmentIdentifiers={["phq-a-8", "gad-7"]} />}
            {parents.map(parent => (
              <AssignmentRow key={`${parent?.id}-phq-gad`} user={parent} assessmentIdentifiers={["phq-a-8", "gad-7"]} />
            ))}
          </div>
          <div className={css(styles.buttonContainer)}>
            <Button
              disabled={assignButtonDisabled}
              theme="lightBlue"
              title="Send assessments"
              onPress={handleAssignAssessments}
            />
          </div>
        </div>
      </ManateeModal>
    </OverlayCenteredView>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 30
  },
  headerText: {
    fontSize: 20,
    fontWeight: 800
  },
  assessment: {
    color: "#000000",
    fontSize: 20,
    padding: 20,
    paddingTop: 30
  },
  name: {
    color: "#000000",
    fontWeight: 800
  },
  disabledColor: {
    color: "#8C9195"
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  rowSpaceBetween: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  },
  assignedColor: {
    backgroundColor: "#38D4DE"
  },
  notApplicableColor: {
    backgroundColor: "#8C9195"
  },
  statusLabelContainer: {
    borderRadius: 15,
    padding: 3,
    paddingLeft: 5,
    paddingRight: 6
  },
  statusLabel: {
    color: "#FFFFFF",
    fontSize: 12,
    fontWeight: 700
  },
  bottomBorder: {
    borderBottom: "1px solid #CBCBCB"
  },
  button: {
    marginTop: 100
  },
  buttonContainer: {
    marginTop: 20
  },
  icon: {
    height: 24,
    width: 24,
    marginLeft: 10,
    marginRight: 5
  }
});
