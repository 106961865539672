import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";
import "./AppointmentScheduler.scss";
import CalendarIcon from "../../assets/calendar-over-blue-blotch.png";
import useAppointmentType from "../../hooks/appointments/useAppointmentType";
import { useGlobalContext } from "../../context/GlobalComponentsContext";
import { useMutation, useQuery } from "@apollo/client";
import { GET_FAMILY_MEMBERS, CREATE_APPOINTMENT, GET_AVAILABLE_TIME_SLOTS } from "../../queries";
import AppointmentDateTimePicker from "./AppointmentDateTimePicker";
import { StyleSheet, css } from "aphrodite";
import { logEvent } from "../../actions";
import { reportError } from "../../utils/errorUtils";
import useCareScreenerWidth from "../../hooks/care-screener/useCareScreenerWidth";
import Flex from "../core/Flex";
import { User } from "../../model/userModels";
import { AppointmentType } from "../../model/appointmentModels";
import Spinner from "../Spinner";
import moment from "moment";
import { getTimeZone } from "@utils/dateUtils";

type AppointmentSchedulerContextType = {
  attendee: User;
  appointmentType: AppointmentType;
  appointmentContactMethod: string;
  careTeamMemberIds: number[];
  selectedTimeSlot: SelectedTimeSlot | undefined;
  setSelectedTimeSlot: Dispatch<SetStateAction<SelectedTimeSlot | undefined>>;
  availableSlots: Record<string, SelectedTimeSlot[]>;
};

type SelectedTimeSlot = { startTime: string; userId: number };

type FilteredSlotsResult = {
  availableSlots: Record<string, SelectedTimeSlot[]>;
};

export const AppointmentSchedulerContext = React.createContext<AppointmentSchedulerContextType | undefined>(undefined);

export default function AppointmentScheduler({
  appointmentId,
  appointmentContactMethod = "Phone Call",
  appointmentUserId,
  onAppointmentScheduled,
  careTeamMemberIds,
  availabilityRecurringInterval,
  availabilityRecurringCount,
  schedulingQueryStartDate,
  hideCalendarIcon = false,
  headerComponent = <></>,
  style = {}
}) {
  const [processing, setProcessing] = useState(false);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState<SelectedTimeSlot | undefined>();

  const { showError } = useGlobalContext();

  const { appointmentType, loading: apptLoading } = useAppointmentType(appointmentId);
  const { data, loading: userLoading, error } = useQuery(GET_FAMILY_MEMBERS);

  useEffect(() => {
    if (error) {
      reportError(error);
      showError();
    }
  }, [error, showError]);

  const user = useMemo(() => data?.me, [data]);
  const attendee = useMemo(
    () => (!appointmentUserId ? user : data && data.familyMembers.find(fm => fm.id === appointmentUserId)),
    [appointmentUserId, user, data]
  );

  const [createAppointmentMutation] = useMutation(CREATE_APPOINTMENT);
  const { widthStyle } = useCareScreenerWidth();

  const handleCreateAppointment = useCallback(async () => {
    try {
      setProcessing(true);
      if (selectedTimeSlot) {
        const result = await createAppointmentMutation({
          variables: {
            appointmentTypeId: appointmentType.id,
            attendeeIds: [attendee.id, selectedTimeSlot.userId],
            date: selectedTimeSlot.startTime,
            contactMethod: appointmentContactMethod
          }
        });

        const appointment = result.data.createAppointment;
        onAppointmentScheduled(appointment);
        logEvent("consultAppointmentScheduled");
      }
    } catch (error) {
      reportError(error);
      showError();
    } finally {
      setProcessing(false);
    }
  }, [
    createAppointmentMutation,
    appointmentType?.id,
    attendee?.id,
    selectedTimeSlot,
    appointmentContactMethod,
    onAppointmentScheduled,
    showError
  ]);

  const startDate = schedulingQueryStartDate || moment().format("YYYY-MM-DD");
  const endDate = moment(startDate).add(31, "days").format("YYYY-MM-DD");

  const { data: timesData, loading: timesLoading } = useQuery(GET_AVAILABLE_TIME_SLOTS, {
    variables: {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone || "America/New_York",
      userIds: careTeamMemberIds?.length > 0 ? careTeamMemberIds : appointmentType?.applicableCareTeamMemberIds || [],
      endDate,
      startDate,
      appointmentTypeId: appointmentType?.id,
      contactMethod: appointmentContactMethod,
      recurringInterval: availabilityRecurringInterval,
      recurringCount: availabilityRecurringCount
    },
    fetchPolicy: "cache-and-network",
    skip: !appointmentType?.id
  });

  const { availableSlots } = useMemo(() => filterAvailableSlots(timesData?.availableTimeSlots), [timesData]);

  if (apptLoading || userLoading || timesLoading || processing) {
    return (
      <Flex center style={{ marginBottom: 100, marginTop: 100 }}>
        <Spinner inline />
      </Flex>
    );
  }

  return (
    <AppointmentSchedulerContext.Provider
      value={{
        attendee,
        appointmentType,
        appointmentContactMethod,
        careTeamMemberIds,
        selectedTimeSlot,
        setSelectedTimeSlot,
        availableSlots
      }}
    >
      <Flex flex center className={css(styles.container)} style={{ ...widthStyle, minWidth: 200, ...style }}>
        {!hideCalendarIcon && <img src={CalendarIcon} className={css(styles.calendarIcon)} />}
        {!!appointmentType && (
          <AppointmentDateTimePicker onContinuePressed={handleCreateAppointment} headerComponent={headerComponent} />
        )}
      </Flex>
    </AppointmentSchedulerContext.Provider>
  );
}

const styles = StyleSheet.create({
  container: {
    alignSelf: "center",
    marginTop: "5%",
    flex: 1,
    paddingBottom: 30,
    position: "relative",
    backgroundColor: "#FFFFFF"
  },
  calendarIcon: {
    width: 80,
    height: 80,
    aspectRatio: 1,
    position: "absolute",
    zIndex: 1000,
    top: 0,
    left: 0
  }
});

function filterAvailableSlots(timeSlots: SelectedTimeSlot[] | undefined): FilteredSlotsResult {
  const MIN_SLOTS_PER_DAY = 5;
  const availableSlots: Record<string, SelectedTimeSlot[]> = {};

  if (!timeSlots) {
    return { availableSlots };
  }

  const slotsByDate: Record<string, SelectedTimeSlot[]> = {};

  timeSlots.forEach(slot => {
    const momentTime = moment(slot.startTime);
    const date = momentTime.tz(getTimeZone()).format("YYYY-MM-DD");
    if (!slotsByDate[date]) {
      slotsByDate[date] = [];
    }
    slotsByDate[date].push(slot);
  });

  for (const date in slotsByDate) {
    const slots = slotsByDate[date];
    const evenSlots = slots.filter(slot => {
      const minutes = moment(slot.startTime).minutes();
      return minutes === 0 || minutes === 30;
    });

    if (evenSlots.length > MIN_SLOTS_PER_DAY) {
      availableSlots[date] = evenSlots;
    } else if (slots.length > MIN_SLOTS_PER_DAY) {
      const remainingSlotsNeeded = MIN_SLOTS_PER_DAY - evenSlots.length;
      const oddSlots = slots.filter(slot => {
        const minutes = moment(slot.startTime).minutes();
        return minutes === 15 || minutes === 45;
      });
      const selectedOddSlots = oddSlots.slice(0, remainingSlotsNeeded);
      availableSlots[date] = [...evenSlots, ...selectedOddSlots];
    } else {
      availableSlots[date] = slots;
    }
  }

  return { availableSlots };
}
