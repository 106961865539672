import React, { useEffect, useMemo } from "react";
import { StyleSheet, css } from "aphrodite";
import usePatientFamilyMembers from "../../../hooks/usePatientFamilyMembers";
import { useLazyQuery } from "@apollo/client";
import { GET_GUIDING_STARS } from "../../../queries";
import { sortBy } from "lodash";

export default function GuidingStar() {
  const { parents } = usePatientFamilyMembers();

  const [getGuidingStars, { data }] = useLazyQuery(GET_GUIDING_STARS);

  useEffect(() => {
    if (parents.length > 0) {
      getGuidingStars({
        variables: {
          userIds: parents.map(p => p.id)
        }
      });
    }
  }, [getGuidingStars, parents]);

  const answerData = useMemo(() => {
    const goals = data?.guidingStars || [];

    return sortBy(
      parents.map(parent => {
        const goal = goals.find(g => g.userId === parent.id);
        return { user: parent, goal };
      }),
      data => (data.goal ? 0 : 1)
    );
  }, [parents, data]);

  return (
    <div className={css(styles.container)}>
      <div className={css(styles.title)}>🌟 Family Guiding Star</div>
      {answerData.map(({ user, goal }, i) => {
        return (
          <div className={css(styles.caregiverRow)} key={user.id + i}>
            <p className={css(styles.name)}>{user.firstName}: </p>
            {goal ? (
              <p className={css(styles.answer)}>{`"${goal.description}"`}</p>
            ) : (
              <p className={css(styles.noAnswer)}>(None set)</p>
            )}
          </div>
        );
      })}
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#E5EEFF",
    borderRadius: 8,
    padding: 30,
    margin: 20,
    marginBottom: 10,
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start"
  },
  caregiverRow: {
    paddingTop: 10,
    display: "flex",
    flexDirection: "row"
  },
  name: {
    color: "#000000",
    fontWeight: 700,
    paddingRight: 5
  },
  noAnswer: {
    color: "#000000",
    fontWeight: 300,
    paddingLeft: 5
  },
  answer: {
    color: "#000000",
    fontWeight: 400,
    fontStyle: "italic"
  },
  containerNoResponses: {
    height: 230
  },
  title: {
    color: "#1A2579",
    fontWeight: 700,
    fontSize: 24,
    marginBottom: 10
  }
});
