import React, { useState } from "react";
import { Candidate, Candidates, InsuranceEligibilityStatus } from "../services/CareScreenerService";
import useArrayIterator, { ArrayIterator } from "../hooks/useArrayIterator";

type CareScreenerContext = {
  candidates: Candidates;
  setCandidates: (candidates: Candidates) => void;
  candidateIterator: ArrayIterator<Candidate>;
  insuranceEligibilityStatus: InsuranceEligibilityStatus | undefined;
  setInsuranceEligibilityStatus: (status: InsuranceEligibilityStatus) => void;
};

export const CareScreenerContext = React.createContext<CareScreenerContext | undefined>(undefined);

export function CareScreenerContextProvider({ children }) {
  const [candidates, setCandidates] = useState<Candidates>([]);
  const candidateIterator = useArrayIterator(candidates);
  const [insuranceEligibilityStatus, setInsuranceEligibilityStatus] = useState<InsuranceEligibilityStatus>();

  return (
    <CareScreenerContext.Provider
      value={{
        candidates,
        setCandidates,
        candidateIterator,
        insuranceEligibilityStatus,
        setInsuranceEligibilityStatus
      }}
    >
      {children}
    </CareScreenerContext.Provider>
  );
}
