import React, { ReactNode, useContext, useState } from "react";
import "./ScheduleAppointment.css";
import config from "../config/env";
import useCareScreenerWidth from "../hooks/care-screener/useCareScreenerWidth";
import { useSearchParams } from "react-router-dom";
import { UserContext } from "../context/UserContext";
import { User } from "../model/userModels";
import { Appointment } from "../model/appointmentModels";
import { ChevronLeft } from "react-feather";
import Flex from "../components/core/Flex";
import Text from "../components/core/Text";
import Spinner from "../components/Spinner";
import Button from "../components/Button";
import MatchedCareProviderSelection from "../components/appointments/MatchedCareProviderSelection";
import AppointmentScheduler from "../components/appointments/AppointmentScheduler";
import BookedAppointment from "../components/appointments/BookedAppointment";

type Props = {
  header?: ReactNode;
  appointmentTypeId?: string;
  availabilityRecurringInterval?: string;
  availabilityRecurringCount?: number;
  schedulingQueryStartDate?: string;
};

export default function ScheduleAppointment({
  header,
  appointmentTypeId: apptTypeId,
  availabilityRecurringCount,
  availabilityRecurringInterval,
  schedulingQueryStartDate
}: Props) {
  const { user } = useContext(UserContext)!;
  const [searchParams] = useSearchParams();
  const { widthStyle } = useCareScreenerWidth({
    smallScreenWidth: "100%",
    smallScreenWidthTrigger: 500,
    largScreenMinWidth: 500
  });
  const [selectedCareProvider, setSelectedCareProvider] = useState<User | undefined>(undefined);
  const [scheduledAppointment, setScheduledAppointment] = useState<Appointment | undefined>(undefined);

  const [view, setView] = useState<"matches" | "scheduler" | "scheduled">("matches");

  const [appointmentTypeId] = useState(
    apptTypeId || searchParams.get("appointmentTypeId") || config.env.REACT_APP_INTAKE_APPT_TYPE_ID
  );
  const [appointmentUserId] = useState(
    searchParams.get("clientId") ? parseInt(searchParams.get("clientId") || "") : user.id
  );

  if (!user) {
    return <Spinner />;
  }

  return (
    <Flex center className="background-image-container">
      <Flex
        style={{
          ...widthStyle,
          flexShrink: 1,
          marginTop: "5vh",
          marginBottom: "5vh",
          borderRadius: 20,
          backgroundColor: "#FFFFFF",
          padding: "2%"
        }}
      >
        {header}
        {view === "matches" && (
          <Flex style={{ gap: 12 }}>
            <MatchedCareProviderSelection
              userId={appointmentUserId}
              selectButtonTitle="Book"
              onCareProviderSelected={provider => {
                setSelectedCareProvider(provider);
                setView("scheduler");
              }}
            />
          </Flex>
        )}
        {view === "scheduler" && (
          <AppointmentScheduler
            appointmentId={appointmentTypeId}
            appointmentUserId={appointmentUserId}
            schedulingQueryStartDate={schedulingQueryStartDate}
            availabilityRecurringCount={availabilityRecurringCount}
            availabilityRecurringInterval={availabilityRecurringInterval}
            appointmentContactMethod="Healthie Video Call"
            onAppointmentScheduled={appointment => {
              setScheduledAppointment(appointment);
              setView("scheduled");
            }}
            careTeamMemberIds={[selectedCareProvider?.id]}
            hideCalendarIcon
            headerComponent={
              <Flex
                flex
                row
                center
                left
                style={{
                  paddingBottom: 10,
                  borderBottomStyle: "solid",
                  borderBottomWidth: 1,
                  borderBottomColor: "#E0E0E0"
                }}
              >
                <Button
                  theme="manaBlueInverted"
                  icon={ChevronLeft}
                  iconSize={40}
                  iconLeft
                  title={() => (
                    <Text theme="manaBlue" weight={700} size={14}>
                      Back to matches
                    </Text>
                  )}
                  style={{ padding: 0 }}
                  onPress={() => setView("matches")}
                />
              </Flex>
            }
          />
        )}
        {view === "scheduled" && scheduledAppointment && <BookedAppointment appointment={scheduledAppointment} />}
      </Flex>
    </Flex>
  );
}
