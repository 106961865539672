import React, { useContext, useEffect } from "react";
import { format } from "date-fns";
import { toDate } from "./dateUtils";
import { AppointmentSchedulerContext } from "./AppointmentScheduler";
import { getTimeZoneName } from "@utils/dateUtils";
import Button from "../Button";
import Flex from "../core/Flex";
import moment from "moment/moment";

export default function AppointmentTimePicker({ selectedDay, onContinuePressed }) {
  const { selectedTimeSlot, setSelectedTimeSlot, availableSlots } = useContext(AppointmentSchedulerContext)!;

  useEffect(() => {
    setSelectedTimeSlot(undefined);
  }, [selectedDay, setSelectedTimeSlot]);

  const date = moment(selectedDay).format("YYYY-MM-DD");

  const filteredSlots = availableSlots[date];

  function availableSlot(slot, index) {
    return (
      <div
        key={index}
        onClick={() => setSelectedTimeSlot(slot)}
        className={`available-slot ${selectedTimeSlot == slot ? "active-slot" : ""}`}
      >
        {format(toDate(slot.startTime), "h:mm a").toLowerCase()}
      </div>
    );
  }

  return (
    <Flex
      flex
      style={{
        padding: 10,
        paddingBottom: 20,
        minHeight: 400,
        borderRadius: 10,
        width: "100%"
      }}
    >
      <div className="availability-on-header">
        <span className="availability-on-header-date">{format(selectedDay, "MMMM d, yyyy")}</span>
        <p className="availability-on-header-timezone">Timezone: {getTimeZoneName()}</p>
      </div>
      <div className="areas-of-day-flexbox">
        {filteredSlots?.length && (
          <div className="available-slots-for-day">
            {filteredSlots.map((slot, index) => availableSlot(slot, index))}
          </div>
        )}

        {!filteredSlots?.length ? (
          <div className="appointment-time-picker-empty-state">
            <p>No available time slots</p>
            <p>Please select a different date in the calendar.</p>
            <p>You can change the month by pressing the arrow at the top right of the calendar.</p>
          </div>
        ) : undefined}
      </div>
      <Button
        style={styles.button}
        theme="manaBlue"
        title="Confirm Appointment"
        disabled={!selectedTimeSlot}
        onPress={onContinuePressed}
      />
    </Flex>
  );
}

const styles = {
  button: {
    minWidth: 250,
    maxWidth: 300,
    alignSelf: "center",
    paddingleft: 10,
    paddingRight: 10
  }
};
