import React, { useContext, useMemo, useState } from "react";
import ClinicalAssessmentChart from "./ClinicalAssessmentChart";
import { logEvent } from "../../actions";
import { FamilyAssessmentContext } from "../../context/FamilyAssessmentContext";
import usePatientFamilyMembers from "../../hooks/usePatientFamilyMembers";
import Filters from "./filters/Filters";
import useAssessmentFilter from "./filters/useAssessmentsFilter";
import useUsersFilter from "./filters/useUsersFilters";
import { StyleSheet, css } from "aphrodite";
import _ from "lodash";
import AssessmentAnswers from "./AssessmentAnswers";
import SendAssessmentsButton from "./SendAssessmentsButton";

const assessmentFieldOverrides = [
  {
    name: "PHQ-8*",
    identifier: "phq-a-8",
    finePrint: "*This PHQ does not include question 9 around suicidal ideation. Scoring is adjusted accordingly."
  }
];

const order = {
  "psc-attention": 1,
  "psc-internalizing": 2,
  "psc-externalizing": 3,
  "psc-focus-area": 4,
  "psc-parenting": 5,
  psc: 6,
  "phq-a-8": 7,
  "phq-a-9": 8,
  "gad-7": 9
};

export default function Assessments() {
  logEvent("therapistViewAssessmentProgress");

  const { assignable, completions, chartableAssessments, showSendAssessmentsButton } =
    useContext(FamilyAssessmentContext);
  const { familyMembers } = usePatientFamilyMembers();
  const [selectedAssessments, setSelectedAssessments] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedCompletion, setSelectedCompletion] = useState(undefined);

  const applicableAssessments = useMemo(
    () => _.orderBy(chartableAssessments || [], a => order[a.identifier] || 1000),
    [chartableAssessments]
  );

  const applicableIdentifierSet = useMemo(
    () => new Set(applicableAssessments.map(a => a.identifier)),
    [applicableAssessments]
  );

  const applicableCompletionsByUserId = useMemo(() => {
    const byUserId = {};
    familyMembers.forEach(m => {
      byUserId[m.id] =
        completions?.filter(c => c.clientId === m.id && applicableIdentifierSet.has(c.assessmentIdentifier)) || [];
    });
    return byUserId;
  }, [applicableIdentifierSet, completions, familyMembers]);

  const applicableIdentifiersByUserId = useMemo(() => {
    const byUserId = {};
    familyMembers.forEach(m => {
      const userAssignable = assignable?.userAssessments?.find(ua => ua.userId === m.id);
      byUserId[m.id] = [...applicableIdentifierSet].filter(
        identifier =>
          userAssignable?.assessmentIdentifiers?.includes(identifier) ||
          applicableCompletionsByUserId[m.id]?.some(c => c.assessmentIdentifier === identifier)
      );
    });
    return byUserId;
  }, [familyMembers, assignable?.userAssessments, applicableIdentifierSet, applicableCompletionsByUserId]);

  const applicableUsers = useMemo(
    () => familyMembers.filter(m => applicableIdentifiersByUserId[m.id]?.length),
    [familyMembers, applicableIdentifiersByUserId]
  );

  const selectableAssessments = useMemo(() => {
    let selectable = applicableAssessments;
    if (selectedUsers?.length) {
      selectable = selectable.filter(a =>
        selectedUsers.some(user => applicableIdentifiersByUserId[user.id]?.includes(a.identifier))
      );
    }
    const ordered = _.orderBy(selectable, a => order[a.identifier] || 1000);
    return ordered;
  }, [applicableAssessments, applicableIdentifiersByUserId, selectedUsers]);

  const selectableUsers = useMemo(() => {
    let users = applicableUsers;
    if (selectedAssessments?.length) {
      users = users.filter(user =>
        selectedAssessments.some(a => applicableIdentifiersByUserId[user.id]?.includes(a.identifier))
      );
    }
    return users;
  }, [applicableIdentifiersByUserId, applicableUsers, selectedAssessments]);

  const chartData = useMemo(() => {
    const data = getChartData(selectedAssessments?.length ? selectedAssessments : selectableAssessments);
    const ordered = _.orderBy(data, a => order[a.identifier] || 1000);
    return ordered;
  }, [selectableAssessments, selectedAssessments]);

  const assessmentsFilter = useAssessmentFilter({
    assessments: applicableAssessments,
    selectable: selectableAssessments,
    selected: selectedAssessments,
    setSelected: setSelectedAssessments
  });
  const usersFilter = useUsersFilter({
    users: applicableUsers,
    selectable: selectableUsers,
    selected: selectedUsers,
    setSelected: setSelectedUsers
  });

  const filters = [];

  if (selectableAssessments?.length) {
    filters.push(assessmentsFilter);
  }
  if (selectableUsers?.length) {
    filters.push(usersFilter);
  }

  if (selectedCompletion) {
    // if the selected completion is a subAssessment, grab the super assessment instead
    const assessment = selectableAssessments.find(sa => {
      if (sa.identifier === selectedCompletion.assessmentIdentifier) {
        return true;
      } else if (sa.subAssessments) {
        return sa.subAssessments.find(sub => sub.identifier === selectedCompletion.assessmentIdentifier);
      }
      return false;
    });

    return (
      <AssessmentAnswers
        assessment={assessment}
        completedAt={selectedCompletion.completedAt}
        userId={selectedCompletion.userId}
        onBackPressed={() => setSelectedCompletion(undefined)}
      />
    );
  }

  return (
    <div className={css(cssStyles.assessments)}>
      <div className={css(cssStyles.headerRow)}>
        <Filters filters={filters} />
        {showSendAssessmentsButton && (
          <div style={styles.assignButtonPosition}>
            <SendAssessmentsButton />
          </div>
        )}
      </div>
      {chartData.map((a, i) => {
        return (
          <ClinicalAssessmentChart
            key={i}
            assessment={a}
            selectedUsers={selectedUsers}
            onCompletionSelected={completion => setSelectedCompletion(completion)}
          />
        );
      })}
    </div>
  );
}

function getChartData(assessments) {
  const chartAssessments = [];

  assessments.forEach(assessment => {
    const overrideFields = assessmentFieldOverrides.find(override => override.identifier === assessment.identifier);
    const updatedAssessment = {
      ...assessment,
      ...overrideFields
    };
    chartAssessments.push(updatedAssessment);

    if (assessment.subAssessments?.length) {
      assessment.subAssessments.forEach(s => {
        chartAssessments.push(s);
      });
    }
  });

  return chartAssessments;
}

const cssStyles = StyleSheet.create({
  headerRow: {
    marginLeft: 20,
    marginRight: 20,
    flex: 1,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  assessments: {
    paddingLeft: 20,
    backgroundColor: "#ffffff"
  }
});

const styles = {
  confirmationTitle: {
    fontFamily: "Helvetica Neue",
    fontSize: 21,
    lineHeight: 1.25,
    fontWeight: 500,
    whiteSpace: "pre-wrap"
  },
  confirmationSubtitle: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    margin: 20
  },
  confirmationName: {
    fontWeight: 700
  },
  assignButton: {
    width: 100,
    margin: 20,
    marginTop: 8
  },
  assignButtonPosition: {
    position: "relative",
    left: -200
  }
};
